import { Stack, Typography } from '@mui/material'
import {
  BodyScrollEndEvent,
  ColDef,
  Column,
  FirstDataRenderedEvent,
  GridOptions,
  ICellRendererParams,
  RowDataUpdatedEvent,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { getTargetRangeArr } from 'components/CalendarHeadings/CalendarHeadings.utils'
import { BudgetAPI } from 'global/api/APIMethods/BudgetAPI'
import { OperationForCreateOrUpdate } from 'global/api/constructors'
import { IFinCenterBudgetQuery } from 'global/api/definitions'
import { changeQueryParams, getActualEsnParams, getQueryParam } from 'global/functions'
import { theme } from 'global/styles/theme'
import {
  IFinCenterOperation,
  IOperationUnit,
  TBudgetType,
  TOperationalActivityCategory,
  TReportType,
} from 'global/types/commos-def'
import { cloneDeep } from 'lodash'
import { calculeteESN, getTotals } from 'pages/BudgetPageNew/components/FinanceCenterRecords/components/utils'
import { StyledAgGrid } from 'pages/ReportPage/ReportPage.style'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { BudgetStartPage } from '../BudgetStartPage'
import { TFormatSelectionValue } from '../FormatSelectionDialog/FormatSelectionDialog.types'
import {
  BudgetCellWrapper,
  BudgetTotalCellStyle,
  TABLE_CELL_HEIGHT,
  TOTAL_ROW_BACKGROUND_COLOR,
} from './BudgetAgGrid.styles'
import { IAgGridTestProps, IFocusedRowData } from './BudgetAgGrid.types'
import { suppressKeyboardEvent } from './BudgetAgGrid.utils'
import { EditableCell } from './cells/EditableCell'
import { EditableCellNumber } from './cells/EditableCellNumber'
import { MenuCell } from './cells/MenuCell'
import { UnitPriceCell } from './cells/UnitPriceCell'

const excludeColumns = ['menu', 'number', 'name', 'unitPriceWithoutVAT', 'group']

export const BudgetAgGrid: FC<IAgGridTestProps> = ({
  operations,
  total,
  ESNParams,
  fillingMode,
  availableYears,
  updateDownloadButtonDisabled,
  onCopyDialogOpen,
  financeCenter,
  updateTableRowCount,
}) => {
  const history = useHistory()
  const reportType = getQueryParam(history, 'reportType') as TReportType
  const category = getQueryParam(history, 'category') as TOperationalActivityCategory
  const financeCenterId = getQueryParam(history, 'financeCenterID') as string
  const gridRef = React.useRef<AgGridReact<any>>(null)
  const isMonthlyReport = reportType === 'MONTHLY' ? true : undefined
  const [localFillingMode, setLocalFillingMode] = useState<TFormatSelectionValue | null>(fillingMode)
  const isQuantityMode = localFillingMode === 'QUANTITY' ? true : undefined
  const isAmountMode = localFillingMode === 'AMOUNT' ? true : undefined
  const rowAutoAdding = getQueryParam(history, 'isCreateNextRow') as string
  const [isTableShown, setIsTableShown] = useState<boolean>(false)
  const [focusedRowData, setFocusedRowData] = useState<IFocusedRowData | null>(null)

  const { t: translate } = useTranslation('ENUMS')

  useEffect(() => {
    if (operations?.length) setIsTableShown(true)
  }, [operations])

  useEffect(() => {
    updateDownloadButtonDisabled(!isTableShown)
  }, [isTableShown])

  const updateOperation = (data: IFinCenterOperation) => {
    const budgetType = getQueryParam(history, 'budgetType') as TBudgetType
    let subBillID: null | number = null
    if (budgetType === 'bdds') {
      if (category === 'INCOME') {
        subBillID = financeCenter?.finIncomeSubBill.subBill.id ?? null
      } else {
        subBillID = financeCenter?.finExpenseSubBill.subBill.id ?? null
      }
    }
    if (budgetType === 'bdr') {
      if (category === 'INCOME') {
        subBillID = financeCenter?.incomeSubBill.subBill.id ?? null
      } else {
        subBillID = financeCenter?.expenseSubBill.subBill.id ?? null
      }
    }
    BudgetAPI.createOrUpdateOperation(
      data,
      {
        financeCenterID: financeCenterId,
        projectID: getQueryParam(history, 'projectID') as string,
        category: category,
        year: getQueryParam(history, 'yearStart') as string,
        analysisType: getQueryParam(history, 'analysisType') as string,
        ...(subBillID && { subBillID }),
      },
      getQueryParam(history, 'budgetType') as TBudgetType,
    ).then(() => {
      let visibleWidth = 0
      let allWidth = 0
      const leftColumns = gridRef.current?.columnApi?.getAllDisplayedVirtualColumns() || []
      const equalColumns =
        gridRef.current?.columnApi?.getColumns()?.filter((col) => !excludeColumns.includes(col.getColId())) || []
      const equalColumnsIds = equalColumns.map((col) => col.getColId())

      leftColumns.forEach((col) => {
        visibleWidth += col.getActualWidth()
      })
      ;(gridRef.current?.columnApi?.getColumns() || []).forEach((col) => {
        allWidth += col.getActualWidth()
      })

      // console.log(allWidth,visibleWidth);
      if (allWidth > visibleWidth) {
        console.log(1)
        gridRef.current?.columnApi?.autoSizeColumns(equalColumnsIds)
        // let maxColWidth = 0
        // const equalColumnsIds = equalColumns.map((col) => col.getColId())
        // equalColumns.forEach((col) => {
        // if (col.getActualWidth() > maxColWidth) {
        //   maxColWidth = col.getActualWidth()
        // }
        // })
        onColumnResized()
        // console.log(maxColWidth)
        // gridRef.current?.columnApi?.setColumnWidths(
        //   equalColumnsIds.map((colId) => ({ key: colId, newWidth: maxColWidth })),
        // )
      } else {
        gridRef.current?.columnApi?.autoSizeColumns(equalColumnsIds)
        onColumnResized()
        console.log(2)
      }
    })
  }

  const getRangeColumns = () => {
    let rangeList: any[] = getTargetRangeArr(history, reportType, translate, undefined, false)

    rangeList = rangeList.map((item, index) => {
      const fieldName: string = `month-${item}-pos-${index}`
      const nextFieldName: string = `month-${rangeList[index + 1]}-pos-${index + 1}`

      return {
        headerName: reportType === 'MONTHLY' ? translate('MONTHS_ABBR.' + item) : translate('QUARTERS.' + item),
        field: fieldName,
        minWidth: 90,
        initialWidth: 90,
        resizable: true,
        cellDataType: false,
        flex: 1,
        suppressKeyboardEvent,
        cellRenderer: (params: ICellRendererParams<IFinCenterOperation>) => {
          const { data, node, api } = params

          if (data?.type === 'TOTAL') {
            const localPinnedBottomRowData = api.getPinnedBottomRow(0)?.data

            return (
              <Stack>
                <Stack height={`${TABLE_CELL_HEIGHT}px !important`}>
                  <EditableCellNumber
                    value={data?.units?.[index]?.sum}
                    viewCellProps={{ style: BudgetTotalCellStyle }}
                  />
                </Stack>

                {category === 'WAGE_FUND' && (
                  <Stack height={`${TABLE_CELL_HEIGHT}px !important`}>
                    <EditableCellNumber
                      value={localPinnedBottomRowData?.esnTotal?.units?.[index]?.sum || 0}
                      viewCellProps={{ style: BudgetTotalCellStyle }}
                    />
                  </Stack>
                )}
              </Stack>
            )
          }

          const onChangeValue = (value: number, type: 'nominal' | 'sum') => {
            const localRowData = cloneDeep(data)
            localRowData!.units[index][type] = value
            const recalculatedOperation = getRecalculatedBDROperation(localRowData!)

            // Total
            const recalculatedTotal = getRecalculatedTotal(data!, recalculatedOperation)
            api.setPinnedBottomRowData([recalculatedTotal])

            checkForRowAutoAdding(params)

            // Update
            updateOperation(recalculatedOperation)
            node.updateData(recalculatedOperation)
            api.redrawRows()
            clearFocusedRowData()
          }

          return (
            <Stack>
              <BudgetCellWrapper height={`${TABLE_CELL_HEIGHT}px !important`}>
                <EditableCellNumber
                  value={data?.units?.[index]?.nominal}
                  onChangeValue={isMonthlyReport && isQuantityMode && ((value) => onChangeValue(value, 'nominal'))}
                  viewCellProps={{
                    style: { textAlign: 'center', height: 'fit-content', fontSize: '14px' },
                  }}
                  // handleFocus={() => handleCellFocus(params, fieldName)}
                  // handleBlur={clearFocusedRowData}
                  forceFocus={focusedRowData?.rowIndex === node.rowIndex && focusedRowData.field === fieldName}
                  handleTab={
                    rangeList[index + 1]
                      ? () => handleTab(Number(node.rowIndex), nextFieldName)
                      : () => handleTab(Number(node.rowIndex) + 1, 'name')
                  }
                />
              </BudgetCellWrapper>
              <BudgetCellWrapper height={`${TABLE_CELL_HEIGHT}px !important`}>
                <EditableCellNumber
                  value={data?.units[index].sum}
                  onChangeValue={isMonthlyReport && isAmountMode && ((value) => onChangeValue(value, 'sum'))}
                  viewCellProps={{
                    style: { textAlign: 'center', height: 'fit-content', fontSize: '14px' },
                  }}
                  // handleFocus={() => handleCellFocus(params, fieldName)}
                  // handleBlur={clearFocusedRowData}
                  forceFocus={focusedRowData?.rowIndex === node.rowIndex && focusedRowData.field === fieldName}
                  handleTab={
                    rangeList[index + 1]
                      ? () => handleTab(Number(node.rowIndex), nextFieldName)
                      : () => handleTab(Number(node.rowIndex) + 1, nextFieldName)
                  }
                />
              </BudgetCellWrapper>
              {category === 'WAGE_FUND' && (
                <BudgetCellWrapper height={`${TABLE_CELL_HEIGHT}px !important`}>
                  <EditableCellNumber
                    value={data?.esnTotal?.units[index].sum || 0}
                    viewCellProps={{
                      style: { textAlign: 'center', height: 'fit-content', fontSize: '14px' },
                    }}
                  />
                </BudgetCellWrapper>
              )}
            </Stack>
          )
        },
        cellStyle: (params: ICellRendererParams<IFinCenterOperation>) => getRowStyle(params),
      }
    })

    return rangeList
  }

  const totalGroupByCategory = useMemo((): string[] => {
    switch (category) {
      case 'WAGE_FUND':
        return ['Зарплата', 'ЕСН']

      default:
        return ['Сумма']
    }
  }, [category])

  const getRowStyle = ({ data, node }: ICellRendererParams<IFinCenterOperation>) => {
    if (data?.type === 'DEFAULT') {
      const isFocused = focusedRowData?.rowIndex === node.rowIndex
      return {
        backgroundColor: theme.palette.bg.white,
        // backgroundColor: isFocused ? '#DDEBF8' : theme.palette.bg.white,
        border: 'none',
        padding: 0,
      }
    } else {
      return {
        backgroundColor: TOTAL_ROW_BACKGROUND_COLOR,
        height: `${TABLE_CELL_HEIGHT * totalGroupByCategory.length}px`,
        border: 'none',
        padding: 0,
      }
    }
  }

  const getHeaderForUnitPrice = useMemo(() => {
    switch (localFillingMode) {
      case 'QUANTITY':
        return category === 'WAGE_FUND' ? 'Сумма, без ЕСН' : `Цена за ед. (без НДС)`

      case 'AMOUNT':
        return 'Сумма'

      default:
        return ''
    }
  }, [localFillingMode])

  const handleCellFocus = useCallback((params: ICellRendererParams<IFinCenterOperation>, field: string) => {
    const { node } = params

    setFocusedRowData({ rowIndex: node.rowIndex || 0, field })
  }, [])

  const handleTab = useCallback((index: number, field: string) => {
    setFocusedRowData({ rowIndex: index, field })
  }, [])

  // const handleTabToNextRow = useCallback(() => {
  //     setFocusedRowData((prev) => ({ rowIndex: Number(prev?.rowIndex) + 1, field: 'name' }))
  // }, [])

  const clearFocusedRowData = useCallback(() => {
    if (focusedRowData) setFocusedRowData(null)
  }, [focusedRowData])

  const columnDefs = useMemo((): ColDef[] => {
    return [
      {
        headerName: '',
        field: 'menu',
        minWidth: 64,
        maxWidth: 64,
        flex: 1,
        pinned: 'left',
        cellRenderer: (params: ICellRendererParams<IFinCenterOperation>) => {
          const { data, node } = params
          if (data?.type === 'TOTAL') {
            return (
              <Stack justifyContent={'center'} alignItems={'center'}>
                <Typography variant="subtitle2" color={theme.palette.primary.main}>
                  Всего:
                </Typography>
              </Stack>
            )
          }

          return (
            <MenuCell
              params={params}
              onAddRow={onAddRow}
              setIsTableShown={setIsTableShown}
              getRecalculatedTotal={getRecalculatedTotal}
              setLocalFillingMode={setLocalFillingMode}
            />
          )
        },
        cellStyle: (params: ICellRendererParams<IFinCenterOperation>) => getRowStyle(params),
      },
      {
        headerName: '№',
        field: 'number',
        minWidth: 64,
        initialWidth: 64,
        resizable: true,
        flex: 1,
        pinned: 'left',
        cellRenderer: (params: ICellRendererParams<IFinCenterOperation>) => {
          const { data, node } = params

          if (data?.type === 'TOTAL') return <></>

          return (
            <BudgetCellWrapper justifyContent={'center'}>
              <Typography variant="body2" textAlign={'center'}>
                {(node.rowIndex || 0) + 1}
              </Typography>
            </BudgetCellWrapper>
          )
        },
        cellStyle: (params: ICellRendererParams<IFinCenterOperation>) => getRowStyle(params),
      },
      {
        headerName: 'Наименование дохода',
        field: 'name',
        minWidth: 240,
        initialWidth: 240,
        resizable: true,
        flex: 1,
        pinned: 'left',
        suppressKeyboardEvent,
        cellRenderer: (params: ICellRendererParams<IFinCenterOperation>) => {
          const { data, node, api } = params
          if (data?.type === 'TOTAL') return <></>

          const onChangeValue = (value: string) => {
            node.setDataValue('name', value)
            updateOperation(data!)

            checkForRowAutoAdding(params)
            clearFocusedRowData()
          }

          return (
            <EditableCell
              value={data?.name}
              onChangeValue={isMonthlyReport && onChangeValue}
              maxLines={2}
              isRequired={true}
              maxLength={80}
              placeholder={'Введите наименование дохода'}
              // handleFocus={() => handleCellFocus(params, 'name')}
              // handleBlur={clearFocusedRowData}
              forceFocus={focusedRowData?.rowIndex === node.rowIndex && focusedRowData.field === 'name'}
              handleTab={() => handleTab(Number(node.rowIndex), 'unitPriceWithoutVAT')}
            />
          )
        },
        cellStyle: (params: ICellRendererParams<IFinCenterOperation>) => getRowStyle(params),
      },
      {
        headerName: getHeaderForUnitPrice,
        field: 'unitPriceWithoutVAT',
        minWidth: 164,
        initialWidth: 164,
        resizable: true,
        cellDataType: false,
        flex: 1,
        pinned: 'left',
        suppressKeyboardEvent,
        cellRenderer: (params: ICellRendererParams<IFinCenterOperation>) => {
          const { data, node, api } = params
          if (data?.type === 'TOTAL') return <></>

          const onChangeValue = (value: number, quantityPerMonth?: number, isShouldSplit?: boolean) => {
            node.setDataValue('unitPriceWithoutVAT', value || 0)
            const recalculatedOperation = getRecalculatedBDROperation(data!, quantityPerMonth, isShouldSplit)
            node.setData(recalculatedOperation)

            // Total
            const recalculatedTotal = getRecalculatedTotal(data!, recalculatedOperation)
            api.setPinnedBottomRowData([recalculatedTotal])
            checkForRowAutoAdding(params)

            // Update
            updateOperation(recalculatedOperation)
            api.redrawRows()
            clearFocusedRowData()
          }

          return (
            <UnitPriceCell
              params={params}
              onChangeValue={isMonthlyReport && onChangeValue}
              fillingMode={localFillingMode}
              // handleFocus={() => handleCellFocus(params, 'unitPriceWithoutVAT')}
              // handleBlur={clearFocusedRowData}
              forceFocus={focusedRowData?.rowIndex === node.rowIndex && focusedRowData.field === 'unitPriceWithoutVAT'}
              handleTab={() => handleTab(Number(node.rowIndex), 'month-JANUARY-pos-0')}
            />
          )
        },
        cellStyle: (params: ICellRendererParams<IFinCenterOperation>) => getRowStyle(params),
      },
      {
        headerName: '',
        field: 'group',
        minWidth: 90,
        initialWidth: 90,
        resizable: true,
        flex: 1,
        pinned: 'left',
        cellRenderer: ({ data }: ICellRendererParams<IFinCenterOperation>) => {
          if (data?.type === 'TOTAL') {
            return (
              <Stack>
                {totalGroupByCategory.map((item: string) => (
                  <Stack padding={1} justifyContent={'center'}>
                    <Typography variant="subtitle2" textAlign={'center'} color={theme.palette.primary.main}>
                      {item}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            )
          }

          return (
            <Stack>
              {bodyGroupByCategory.map((item: string) => (
                <BudgetCellWrapper padding={1}>
                  <Typography variant="body2" textAlign={'center'}>
                    {item}
                  </Typography>
                </BudgetCellWrapper>
              ))}
            </Stack>
          )
        },
        cellStyle: (params: ICellRendererParams<IFinCenterOperation>) => getRowStyle(params),
      },
      ...getRangeColumns(),
      {
        headerName: 'Итого',
        field: 'result',
        minWidth: 90,
        initialWidth: 90,
        resizable: true,
        flex: 1,
        suppressKeyboardEvent,
        cellRenderer: ({ data, api }: ICellRendererParams<IFinCenterOperation>) => {
          if (data?.type === 'TOTAL') {
            const localPinnedBottomRowData = api.getPinnedBottomRow(0)?.data

            return (
              <Stack>
                <Stack height={`${TABLE_CELL_HEIGHT}px !important`}>
                  <EditableCellNumber
                    value={data.units.slice(-1)[0].sum}
                    viewCellProps={{ style: BudgetTotalCellStyle }}
                  />
                </Stack>

                {category === 'WAGE_FUND' && (
                  <Stack height={`${TABLE_CELL_HEIGHT}px !important`}>
                    <EditableCellNumber
                      value={localPinnedBottomRowData?.esnTotal?.units.slice(-1)[0].sum || 0}
                      viewCellProps={{ style: BudgetTotalCellStyle }}
                    />
                  </Stack>
                )}
              </Stack>
            )
          }

          return (
            <Stack>
              <BudgetCellWrapper height={`${TABLE_CELL_HEIGHT}px !important`}>
                <EditableCellNumber
                  value={data?.units.slice(-1)[0].nominal}
                  viewCellProps={{
                    style: { textAlign: 'center', height: 'fit-content', fontSize: '14px' },
                  }}
                />
              </BudgetCellWrapper>
              <BudgetCellWrapper justifyContent={'center'} height={`${TABLE_CELL_HEIGHT}px !important`}>
                <EditableCellNumber
                  value={data?.units.slice(-1)[0].sum}
                  viewCellProps={{
                    style: { textAlign: 'center', height: 'fit-content', fontSize: '14px' },
                  }}
                />
              </BudgetCellWrapper>
              {category === 'WAGE_FUND' && (
                <BudgetCellWrapper justifyContent={'center'} height={`${TABLE_CELL_HEIGHT}px !important`}>
                  <EditableCellNumber
                    value={getTotals(data!, 'ESN') || 0}
                    viewCellProps={{
                      style: { textAlign: 'center', height: 'fit-content', fontSize: '14px' },
                    }}
                  />
                </BudgetCellWrapper>
              )}
            </Stack>
          )
        },
        cellStyle: (params: ICellRendererParams<IFinCenterOperation>) => getRowStyle(params),
      },
    ]
  }, [localFillingMode, rowAutoAdding, focusedRowData])

  const getInitialTotal = () => {
    let query = Object.fromEntries(new URLSearchParams(history.location.search)) as unknown as IFinCenterBudgetQuery
    return new OperationForCreateOrUpdate(query, 'TOTAL')
  }

  const onAddRow = (addIndex: number, rowRecordId?: number, isFirstAdd?: boolean) => {
    if (!gridRef.current) return
    setIsTableShown(true)

    const { api } = gridRef.current
    let query = Object.fromEntries(new URLSearchParams(history.location.search)) as unknown as IFinCenterBudgetQuery
    let newOperation = new OperationForCreateOrUpdate(query)

    const budgetType = getQueryParam(history, 'budgetType') as TBudgetType
    let subBillID: null | number = null
    if (budgetType === 'bdds') {
      if (category === 'INCOME') {
        subBillID = financeCenter?.finIncomeSubBill.subBill.id ?? null
      } else {
        subBillID = financeCenter?.finExpenseSubBill.subBill.id ?? null
      }
    }
    if (budgetType === 'bdr') {
      if (category === 'INCOME') {
        subBillID = financeCenter?.incomeSubBill.subBill.id ?? null
      } else {
        subBillID = financeCenter?.expenseSubBill.subBill.id ?? null
      }
    }

    BudgetAPI.createOrUpdateOperation(
      newOperation,
      {
        financeCenterID: query.financeCenterID,
        projectID: query.projectID,
        category: query.category,
        year: query.yearStart,
        analysisType: 'PLAN',
        rowRecordId,
        ...(subBillID && { subBillID }),
      },
      getQueryParam(history, 'budgetType') as TBudgetType,
    ).then((data) => {
      newOperation.id = data

      if (isFirstAdd) {
        let initialTotal = getInitialTotal()
        setTimeout(() => {
          api.setPinnedBottomRowData([initialTotal])
        }, 0)
      }

      changeQueryParams(history, {
        reportType: 'MONTHLY',
        monthStart: 'JANUARY',
        monthEnd: 'DECEMBER',
      })

      api.applyTransaction({
        addIndex,
        add: [newOperation],
      })

      api.redrawRows()
    })
  }

  const getRecalculatedTotal = (prevState: IFinCenterOperation, newState: IFinCenterOperation) => {
    if (!gridRef.current) return

    const { api } = gridRef.current
    const localTotal: IFinCenterOperation = api.getPinnedBottomRow(0)?.data

    if (!localTotal) return
    let copyTotal = cloneDeep(localTotal)
    copyTotal.units = localTotal?.units?.map((unit: IOperationUnit, totalUnitIndex: number) => {
      prevState.units.map((prevStateUnit: IOperationUnit, prevStateUnitIndex: number) => {
        newState.units.map((newStateUnit, newStateUnitIndex: number) => {
          if (
            totalUnitIndex == prevStateUnitIndex &&
            prevStateUnitIndex == newStateUnitIndex &&
            newStateUnitIndex == totalUnitIndex &&
            prevStateUnit.sum != newStateUnit.sum
          ) {
            let difference = newStateUnit.sum - prevStateUnit.sum
            unit.sum += difference
          }
        })
      })
      return unit
    })

    if (category === 'WAGE_FUND') {
      // @ts-ignore
      copyTotal.esnTotal.units = localTotal?.esnTotal?.units.map((unit: IOperationUnit, totalUnitIndex: number) => {
        prevState.esnTotal?.units.map((prevStateUnit: IOperationUnit, prevStateUnitIndex: number) => {
          newState.esnTotal?.units.map((newStateUnit, newStateUnitIndex: number) => {
            if (
              totalUnitIndex == prevStateUnitIndex &&
              prevStateUnitIndex == newStateUnitIndex &&
              newStateUnitIndex == totalUnitIndex &&
              prevStateUnit.sum != newStateUnit.sum
            ) {
              let difference = newStateUnit.sum - prevStateUnit.sum
              unit.sum += difference
            }
          })
        })
        return unit
      })
    }

    return copyTotal
  }

  const getRecalculatedBDROperation = (
    operation: IFinCenterOperation,
    quantityPerMonth?: number,
    isShouldSplit?: boolean,
  ) => {
    let totalNominal = 0
    let copy = JSON.parse(JSON.stringify(operation))
    let targetArray = copy.units.map((unit: IOperationUnit) => {
      if (unit.type != 'TOTAL') {
        if (localFillingMode === 'QUANTITY') {
          if (quantityPerMonth) {
            unit.nominal = quantityPerMonth
            unit.sum = unit.nominal! * copy.unitPriceWithoutVAT!
          } else {
            unit.sum = unit.nominal! * copy.unitPriceWithoutVAT!
          }
        }

        if (localFillingMode === 'AMOUNT') {
          if (!copy.unitPriceWithoutVAT) {
            unit.nominal = 0
            if (isShouldSplit) unit.sum = 0
          } else {
            if (isShouldSplit) {
              unit.sum = copy.unitPriceWithoutVAT / 12
              unit.nominal = unit.sum! / copy.unitPriceWithoutVAT!
            } else {
              unit.nominal = unit.sum! / copy.unitPriceWithoutVAT!
            }
          }
        }

        totalNominal += unit.nominal!
      } else {
        unit.nominal = totalNominal
        unit.sum = totalNominal * copy.unitPriceWithoutVAT!
      }
      return unit
    })
    copy.units = targetArray

    if (ESNParams != null && category === 'WAGE_FUND') {
      calculeteESN(copy, getActualEsnParams(ESNParams.ensType, ESNParams.esnFSSPercent))
    }

    return copy
  }

  const checkForRowAutoAdding = ({ node, api, data }: ICellRendererParams<IFinCenterOperation>) => {
    // @ts-ignore
    if (node.rowIndex === api.getModel().rowsToDisplay.length - 1 && rowAutoAdding === 'ACTIVE') {
      onAddRow(node.rowIndex + 1)
    }
  }

  const bodyGroupByCategory = useMemo((): string[] => {
    switch (category) {
      case 'WAGE_FUND':
        return ['Кол-во', 'Зарплата', 'ЕСН']

      default:
        return ['Кол-во', 'Сумма']
    }
  }, [category])

  const gridOptions: GridOptions = {
    headerHeight: 80,
    rowHeight: (bodyGroupByCategory.length || 1) * TABLE_CELL_HEIGHT,
    enableCellTextSelection: true,
    suppressColumnVirtualisation: true,
  }

  const createFirstOperation = (fillingMode: TFormatSelectionValue) => {
    setLocalFillingMode(fillingMode)

    if (localFillingMode === 'UNDEFINED') {
      BudgetAPI.changeBudgetFillingMode(Number(financeCenterId), { fillingMode }).then(() => {
        onAddRow(0, undefined, true)
      })
    } else onAddRow(0, undefined, true)
  }

  const onRowDataUpdated = (event: RowDataUpdatedEvent<any>) => {
    const count = event.api.getDisplayedRowCount()
    updateTableRowCount(count)
  }

  // React.useEffect(()=>{
  // 	console.log('reload')
  // 	if (gridRef.current) {
  // 		const colIDs = gridRef.current?.columnApi
  // 			?.getColumns()
  // 			?.map((col) => col.getColId())
  // 			?.filter((_) => !excludeColumns.includes(_)) || []
  // 		gridRef.current?.columnApi?.autoSizeColumns(colIDs)
  // 	}
  //
  // },[gridRef.current])

  const updateColumns = useCallback(
    (params: BodyScrollEndEvent<any, any> | FirstDataRenderedEvent<any, any>) => {
      let visibleWidth = 0
      let allWidth = 0
      const colIDs =
        params?.columnApi
          ?.getColumns()
          ?.map((col) => {
            // if (!grid && col.gridOptionService) {
            // }
            return col.getColId()
          })
          ?.filter((_) => !excludeColumns.includes(_)) || []

      const leftColumns = params.columnApi.getAllDisplayedVirtualColumns() || []

      leftColumns.forEach((col) => {
        visibleWidth += col.getActualWidth()
      })
      ;(params.columnApi.getColumns() || []).forEach((col) => {
        allWidth += col.getActualWidth()
      })
      if (allWidth > visibleWidth) {
        params?.columnApi?.autoSizeColumns(colIDs)
        onColumnResized()
      } else {
        let isColsOverflow = false
        const equalColumns =
          gridRef.current?.columnApi?.getColumns()?.filter((col) => !excludeColumns.includes(col.getColId())) || []
        // устанавливаем автоширину колонок, если хотя бы одна не вмещает значение
        for (const col of equalColumns) {
          const cells = document.querySelectorAll(`.ag-cell[col-id="${col.getColId()}"] .table-input`)
          for (let i = 0; i < cells.length; i++) {
            const cell = cells[i]
            if (cell.scrollWidth > cell.clientWidth) {
              isColsOverflow = true
              break
            }
          }
          if (isColsOverflow) break
        }
        if (isColsOverflow) {
          params?.columnApi?.autoSizeColumns(colIDs)
          onColumnResized()
        }
      }
    },
    [gridRef.current],
  )

  const onColumnResized = useCallback(() => {
    let maxColWidth = 0
    const equalColumns =
      gridRef.current?.columnApi?.getColumns()?.filter((col) => !excludeColumns.includes(col.getColId())) || []
    const equalColumnsIds = equalColumns.map((col) => col.getColId())
    equalColumns.forEach((col) => {
      if (col.getActualWidth() > maxColWidth) {
        maxColWidth = col.getActualWidth()
      }
    })
    gridRef.current?.columnApi?.setColumnWidths(equalColumnsIds.map((colId) => ({ key: colId, newWidth: maxColWidth })))
  }, [gridRef.current])

  const gridApi = gridRef.current?.api

  // Получение ширины всей таблицы
  // 					;
  // // Получение всех колонок таблицы
  // 					const columns = gridApi?.getColumnDefs() || [];
  //
  // // Вычисление суммарной ширины столбцов
  // 					console.log(columnsWidth)
  // 					console.log(tableWidth)
  const updatedColumns = useRef<Column<any>[]>([])

  useEffect(() => {
    // const wrapperGrid = document.getElementById('StyledAgGrid')
    // console.log(wrapperGrid );
    // console.log(gridRef.current);
    // if (gridRef.current ) {
    // 	const updatedColumns = (gridRef.current?.columnApi?.getColumns()||[]);
    // 	const tableWidth = 0;
    // 	let columnsWidth = 0;
    // 	updatedColumns.forEach((column) => {
    // 		console.log(column)
    // 		columnsWidth += column.getActualWidth()
    // 	})
    // 	console.group('width columns and wrapper');
    // 	console.log(wrapperGrid);
    // 	console.log(updatedColumns)
    // 	console.log('columnsWidth', columnsWidth);
    // 	console.log('tableWidth', tableWidth);
    // 	console.groupEnd()
    // 	const colIDs = updatedColumns.map((col) => col.getColId())
    // 		.filter((_) => !excludeColumns.includes(_))
    // if (tableWidth > columnsWidth) {
    // 	console.log(1);
    // 	console.log(excludeColumns);
    // 	gridRef.current?.columnApi?.autoSizeColumns(excludeColumns)
    // }else{
    // console.log(2);
    // console.log(colIDs)
    // gridRef.current?.columnApi?.autoSizeColumns(excludeColumns)
    // }
    // }
  }, [gridRef.current])

  return (
    <Stack paddingLeft={'20px'} height={'100%'}>
      {/* @ts-ignore */}
      <Stack display={!isTableShown ? 'none' : 'auto'}>
        <StyledAgGrid
          gridId={'StyledAgGrid'}
          ref={gridRef}
          columnDefs={columnDefs}
          onGridReady={(params) => {
            console.log(params)
            updatedColumns.current = params.columnApi.getColumns() || []
          }}
          rowData={operations}
          gridOptions={gridOptions}
          // onBodyScrollEnd={updateColumns}
          onFirstDataRendered={updateColumns}
          pinnedBottomRowData={[total || getInitialTotal()]}
          isBudgetTable={true}
          footerHeight={totalGroupByCategory.length * TABLE_CELL_HEIGHT}
          suppressCellFocus={true}
          noRowsOverlayComponent={() => <></>}
          onRowDataUpdated={onRowDataUpdated}
          // onCellClicked={handleCellClicked}
        />
      </Stack>

      {/* @ts-ignore */}
      {!isTableShown && (
        <BudgetStartPage
          fillingMode={localFillingMode!}
          createOperation={createFirstOperation}
          availableYears={availableYears}
          onCopyDialogOpen={onCopyDialogOpen}
        />
      )}
    </Stack>
  )
}
