import qs from 'qs'
import React from 'react'
import { Delete } from '@mui/icons-material'
import {
  Box,
  Button,
  Table,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableProps,
  TableRow,
  TableRowProps,
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import { DeleteConfirmDialog } from '../../../components/Dialogs/DeleteConfirmDialog'
import { EENumber } from '../../../components/editableElements/EENumber'
import { EEString } from '../../../components/editableElements/EEString'
import {
  EETableRowProvider,
  EETableRowContext,
  IValidationErrors,
} from '../../../components/editableElements/EETableRow'
import { ProductionProgramAPI } from '../../../global/api/APIMethods/ProductionProgramAPI'
import { IProductionProgramEstimateGetListQuery } from '../../../global/api/definitions'
import { getDeepCopy } from '../../../global/functions'
import { usePrevState } from '../../../global/hooks'
import { scrollableWrapper, tableCellInput_ViewMode, tableCellInput, table } from '../../../global/styles/presets'
import { theme } from '../../../global/styles/theme'
import { IPrPrEstimateOperation, TEEMode, IPrPrEstimateTotal } from '../../../global/types/commos-def'
import { IProductionProgramState } from '../ProductionProgram-def'

interface IEstimateRowProps {
  operation: IPrPrEstimateOperation
  prPrState: IProductionProgramState
  setPrPrState: React.Dispatch<React.SetStateAction<IProductionProgramState>>
}

interface IEstimateRowState {
  changed: boolean
  mode: TEEMode
  operation: IPrPrEstimateOperation
  delta: Partial<IPrPrEstimateTotal>
  isDeleteConfirmOpen: boolean
}

interface IEstimateTotalProps {
  total: IPrPrEstimateTotal
}

export const EstimateTable = {
  Wrapper: function Wrapper(props: TableProps) {
    const { children } = props
    return (
      <Box mr={3}>
        <TableContainer
          {...props}
          sx={{ ...scrollableWrapper, height: 'calc(100vh - 190px)', ...props.sx, pl: 0, ml: 2.5 }}
        >
          <Table stickyHeader>{children}</Table>
        </TableContainer>
      </Box>
    )
  },
  Workload: {
    Headings: function Headings(props: TableRowProps) {
      return (
        <TableHead>
          <TableRow
            sx={{
              '.MuiTableCell-root': {
                border: 'none',
              },
            }}
          >
            <TableCell width="44px"></TableCell>
            <TableCell colSpan={3} />
            <TableCell colSpan={2} sx={{ background: theme.palette.secondary.main }}>
              ГЕНПОДРЯД
            </TableCell>
            <TableCell colSpan={3} sx={{ background: theme.palette.secondary.main }}>
              СУБПОДРЯД
            </TableCell>
            <TableCell colSpan={2} />
          </TableRow>
          <TableRow
            sx={{
              '.MuiTableCell-root': {
                borderRadius: '0 !important',
              },
            }}
          >
            <TableCell width="44px"></TableCell>
            <TableCell sx={{ width: '350px', textAlign: 'left', paddingX: 2 }}> Наименование работ</TableCell>
            <TableCell sx={{ width: '140px' }}>Кол-во</TableCell>
            <TableCell sx={{ width: '140px' }}>Ед. измерения</TableCell>
            <TableCell sx={{ background: theme.palette.secondary.main, width: '140px' }}>Цена за единицу</TableCell>
            <TableCell sx={{ background: theme.palette.secondary.main, minWidth: '140px' }}>Сумма</TableCell>
            <TableCell sx={{ background: theme.palette.secondary.main, width: '140px' }}>Цена за единицу</TableCell>
            <TableCell sx={{ background: theme.palette.secondary.main, minWidth: '140px' }}>Сумма</TableCell>
            <TableCell sx={{ background: theme.palette.secondary.main, width: '200px' }}>Субподрядчик</TableCell>
            <TableCell sx={{ minWidth: '140px' }}>Прибыль</TableCell>
            <TableCell sx={{ minWidth: '140px' }}>Рентабельность</TableCell>
          </TableRow>
        </TableHead>
      )
    },
    Row: function Row(props: IEstimateRowProps) {
      const { operation, setPrPrState, prPrState } = props
      const [state, setState] = React.useState({
        mode: 'view',
        changed: false,
        operation: operation,
        isDeleteConfirmOpen: false,
        delta: {
          genContractTotal: 0,
          subContractTotal: 0,
          income: 0,
          rentability: 0,
        },
      } as IEstimateRowState)

      const prevDelta = usePrevState(state.delta) as unknown as IEstimateRowState['delta']

      let timeout: NodeJS.Timeout

      React.useEffect(() => {
        state.changed && applyDiffOperationDeltaToEstimateTotal(getDiffBetweenOperationDeltas(prevDelta, state.delta))
      }, [state.delta])

      React.useEffect(() => {
        if (state.changed)
          timeout = setTimeout(
            () => recalculateDeltaBetweenInitialAndChangedOperation(operation, state.operation, state.delta),
            300,
          )
        return () => {
          clearTimeout(timeout)
        }
      }, [state.operation])

      const history = useHistory()

      function updateOperation() {
        ProductionProgramAPI.updateOperation(state.operation)
      }

      function switchDeleteConfirm() {
        setState((prevState) => ({
          ...prevState,
          isDeleteConfirmOpen: !prevState.isDeleteConfirmOpen,
        }))
      }

      function recalculateWorkloadOperation(
        value: string | number | boolean | undefined,
        key: keyof IPrPrEstimateOperation,
        operation: IPrPrEstimateOperation,
      ) {
        // @ts-ignore
        let copy = getDeepCopy(operation)
        copy[key] = value

        function calculate(value: Array<keyof IPrPrEstimateOperation>) {
          if (value.includes('subContractTotal')) {
            copy.subContractTotal = (copy.quantity || 0) * (copy.subContractUnitPrice || 0)
          }
          if (value.includes('genContractTotal')) {
            copy.genContractTotal = (copy.quantity || 0) * (copy.genContractUnitPrice || 0)
          }
          if (value.includes('income')) {
            copy.income = (copy.genContractTotal || 0) - (copy.subContractTotal || 0)
          }
          if (value.includes('rentability')) {
            copy.rentability =
              copy.genContractTotal && copy.genContractTotal > 0 ? (copy.income / copy.genContractTotal) * 100 : 0
          }
        }
        switch (key) {
          case 'quantity':
            calculate(['genContractTotal', 'subContractTotal', 'income', 'rentability'])
            break
          case 'genContractUnitPrice':
            calculate(['genContractTotal', 'income', 'rentability'])
            break
          case 'subContractUnitPrice':
            calculate(['genContractTotal', 'subContractTotal', 'income', 'rentability'])
            break
        }
        // let copy = getDeepCopy(operation)
        setState((prevState) => ({
          ...prevState,
          operation: copy,
          changed: true,
        }))
      }

      function changeOperationStringValue(key: 'name' | 'unitType' | 'subContractContractor', value: string) {
        setState((prevState) => ({
          ...prevState,
          operation: {
            ...state.operation,
            [key]: value,
          },
        }))
      }

      function recalculateDeltaBetweenInitialAndChangedOperation(
        propsOperation: IPrPrEstimateOperation,
        stateOperation: IPrPrEstimateOperation,
        delta: IEstimateRowState['delta'],
      ) {
        let deltaCopy = delta && getDeepCopy(delta)
        for (let key in deltaCopy) {
          deltaCopy[key] =
            (stateOperation[key as keyof IPrPrEstimateTotal] || 0) -
            (propsOperation[key as keyof IPrPrEstimateTotal] || 0)
        }
        setState((prevState) => ({
          ...prevState,
          delta: deltaCopy,
        }))
      }

      function getDiffBetweenOperationDeltas(
        prevDelta: IEstimateRowState['delta'],
        newDelta: IEstimateRowState['delta'],
      ): IEstimateRowState['delta'] {
        let targetDelta = {} as IEstimateRowState['delta']
        for (let key in prevDelta) {
          targetDelta[key as keyof IEstimateRowState['delta']] =
            (newDelta[key as keyof IPrPrEstimateTotal] || 0) - (prevDelta[key as keyof IPrPrEstimateTotal] || 0)
        }
        return targetDelta
      }

      function applyDiffOperationDeltaToEstimateTotal(targetDelta: IEstimateRowState['delta']) {
        let totalCopy = prPrState.recalculatedTotal && getDeepCopy(prPrState.recalculatedTotal)
        for (let key in totalCopy) {
          totalCopy[key as keyof IEstimateRowState['delta']] +=
            targetDelta[key as keyof IEstimateRowState['delta']] || 0
        }
        if (totalCopy) {
          totalCopy['rentability'] = totalCopy['genContractTotal']
            ? ((totalCopy['income'] || 0) / totalCopy['genContractTotal']) * 100
            : 0
        }
        setPrPrState((prevState) => ({
          ...prevState,
          recalculatedTotal: totalCopy,
        }))
      }

      function deleteOperation() {
        ProductionProgramAPI.deleteOperation(state.operation, {
          ...(qs.parse(history.location.search, {
            ignoreQueryPrefix: true,
          }) as unknown as IProductionProgramEstimateGetListQuery),
          analysisType: 'PLAN',
        }).then(() => {
          let newTotal = prPrState.total && getDeepCopy(prPrState.total)
          for (let key in newTotal) {
            newTotal[key as keyof IEstimateRowState['delta']] -=
              state.operation[key as keyof IEstimateRowState['delta']] || 0
          }

          setPrPrState((prevState) => ({
            ...prevState,
            operations: (prevState.operations as IPrPrEstimateOperation[])!.filter(
              (operation) => operation.id !== state.operation.id,
            ),
            recalculatedTotal: {
              ...newTotal,
            },
            total: {
              ...newTotal,
            },
          }))
        })
        switchDeleteConfirm()
      }
      return (
        <EETableRowProvider
          onSwitchMode={(prevMode, currentMode) => prevMode == 'edit' && updateOperation()}
          validation={() => validateRow(state.operation)}
        >
          <DeleteConfirmDialog
            open={state.isDeleteConfirmOpen}
            onClose={switchDeleteConfirm}
            onYes={deleteOperation}
            onNo={switchDeleteConfirm}
          />
          <EETableRowContext.Consumer>
            {(value) => (
              <>
                <TableCell>
                  <Box display="flex" justifyContent="center">
                    <Button
                      color={'error'}
                      size="small"
                      sx={{ marginX: 'auto', p: 0.5 }}
                      onClick={() => switchDeleteConfirm()}
                    >
                      <Delete color={'error'} fontSize="small" />
                    </Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <EEString
                    align="left"
                    name="name"
                    maxLength={201}
                    mode={value.mode}
                    value={state.operation.name || ''}
                    onChange={(e) => changeOperationStringValue('name', e.target.value)}
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      error: !value?.validationErrors?.name?.isValid,
                      sx: tableCellInput,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <EENumber
                    name="quantity"
                    mode={value.mode}
                    value={state.operation.quantity || 0}
                    onNumberChange={(values) =>
                      recalculateWorkloadOperation(values.floatValue, 'quantity', state.operation)
                    }
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    /* NumberFormatProps={{
                                            decimalScale: 0,
                                        }} */
                  />
                </TableCell>
                <TableCell>
                  <EEString
                    name="unitType"
                    mode={value.mode}
                    value={state.operation.unitType || ''}
                    onChange={(e) => changeOperationStringValue('unitType', e.target.value)}
                    viewModeStyle={{ ...tableCellInput_ViewMode, justifyContent: 'center' }}
                    TextFieldProps={{
                      sx: { ...tableCellInput, '& input': { ...tableCellInput['input'], textAlign: 'center' } },
                      error: !value?.validationErrors?.unitType?.isValid,
                    }}
                  />
                </TableCell>
                <TableCell sx={{ borderLeft: `1px solid ${theme.palette.grey[300]}` }}>
                  <EENumber
                    name="genContractUnitPrice"
                    mode={value.mode}
                    value={state.operation.genContractUnitPrice || 0}
                    onNumberChange={(values) =>
                      recalculateWorkloadOperation(values.floatValue, 'genContractUnitPrice', state.operation)
                    }
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    /* NumberFormatProps={{
                                            decimalScale: 0,
                                        }} */
                  />
                </TableCell>
                <TableCell>
                  <EENumber
                    name="genContractTotal"
                    mode={'view'}
                    value={state.operation.genContractTotal || 0}
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    /*  NumberFormatProps={{
                                            decimalScale: 0,
                                        }} */
                  />
                </TableCell>
                <TableCell sx={{ borderLeft: `1px solid ${theme.palette.grey[300]}` }}>
                  <EENumber
                    name="subContractUnitPrice"
                    mode={value.mode}
                    value={state.operation.subContractUnitPrice || 0}
                    onNumberChange={(values) =>
                      recalculateWorkloadOperation(values.floatValue, 'subContractUnitPrice', state.operation)
                    }
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    /* NumberFormatProps={{
                                            decimalScale: 0,
                                        }} */
                  />
                </TableCell>
                <TableCell>
                  <EENumber
                    name="subContractTotal"
                    mode={'view'}
                    value={state.operation.subContractTotal || 0}
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    /* NumberFormatProps={{
                                            decimalScale: 0,
                                        }} */
                  />
                </TableCell>
                <TableCell sx={{ borderRight: `1px solid ${theme.palette.grey[300]}` }}>
                  <EEString
                    name="subContractContractor"
                    mode={value.mode}
                    value={state.operation.subContractContractor || ''}
                    onChange={(e) => changeOperationStringValue('subContractContractor', e.target.value)}
                    viewModeStyle={{ ...tableCellInput_ViewMode, justifyContent: 'center' }}
                    TextFieldProps={{
                      sx: { ...tableCellInput, '& input': { ...tableCellInput['input'], textAlign: 'center' } },
                    }}
                  />
                </TableCell>
                <TableCell>
                  <EENumber
                    name="income"
                    mode={'view'}
                    value={state.operation.income || 0}
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    NumberFormatProps={{
                      allowNegative: true,
                      //decimalScale: 0,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <EENumber
                    name="rentability"
                    mode={'view'}
                    value={state.operation.rentability || 0}
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    NumberFormatProps={{
                      allowNegative: true,
                      suffix: ' %',
                    }}
                  />
                </TableCell>
              </>
            )}
          </EETableRowContext.Consumer>
        </EETableRowProvider>
      )
    },
    Total: function Total(props: IEstimateTotalProps) {
      const { total } = props
      return (
        <TableFooter sx={table.footer.sticky}>
          <TableRow>
            <TableCell width="44px" />
            <TableCell colSpan={3} sx={{ paddingX: 2 }}>
              ВСЕГО
            </TableCell>
            <TableCell></TableCell>
            <TableCell align="center">
              <EENumber
                name="genContractTotal"
                mode={'view'}
                value={total.genContractTotal || 0}
                viewModeStyle={tableCellInput_ViewMode}
                TextFieldProps={{
                  sx: tableCellInput,
                }}
                /* NumberFormatProps={{
                                    decimalScale: 0,
                                }} */
              />
            </TableCell>
            <TableCell></TableCell>
            <TableCell align="center">
              <EENumber
                name="subContractTotal"
                mode={'view'}
                value={total.subContractTotal || 0}
                viewModeStyle={tableCellInput_ViewMode}
                TextFieldProps={{
                  sx: tableCellInput,
                }}
                /*  NumberFormatProps={{
                                    decimalScale: 0,
                                }} */
              />
            </TableCell>
            <TableCell></TableCell>
            <TableCell align="center">
              <EENumber
                name="income"
                mode={'view'}
                value={total.income || 0}
                viewModeStyle={tableCellInput_ViewMode}
                TextFieldProps={{
                  sx: tableCellInput,
                }}
                NumberFormatProps={{
                  allowNegative: true,
                  //decimalScale: 0,
                }}
              />
            </TableCell>
            <TableCell align="center">
              <EENumber
                name="rentability"
                mode={'view'}
                value={total.rentability || 0}
                viewModeStyle={tableCellInput_ViewMode}
                TextFieldProps={{
                  sx: tableCellInput,
                }}
                NumberFormatProps={{
                  allowNegative: true,
                  suffix: ' %',
                }}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      )
    },
  },
  Laborious: {
    Headings: function Headings(props: TableRowProps) {
      return (
        <TableHead>
          <TableRow
            sx={{
              '.MuiTableCell-root': {
                border: 'none',
              },
            }}
          >
            <TableCell width="44px"></TableCell>
            <TableCell colSpan={3} />
            <TableCell colSpan={2} sx={{ background: theme.palette.secondary.main }}>
              ГЕНПОДРЯД
            </TableCell>
            <TableCell colSpan={3} sx={{ background: theme.palette.secondary.main }}>
              СУБПОДРЯД
            </TableCell>
            <TableCell colSpan={3} />
          </TableRow>
          <TableRow
            sx={{
              '.MuiTableCell-root': {
                borderRadius: '0 !important',
              },
            }}
          >
            <TableCell width="44px"></TableCell>
            <TableCell sx={{ width: '350px', textAlign: 'left', paddingX: 2 }}> Наименование работ</TableCell>
            <TableCell sx={{ width: '140px' }}>Кол-во ч/ч</TableCell>
            <TableCell sx={{ width: '140px' }}>Кол-во м/ч</TableCell>
            <TableCell sx={{ background: theme.palette.secondary.main, width: '140px' }}>Цена за час</TableCell>
            <TableCell sx={{ background: theme.palette.secondary.main, minWidth: '140px' }}>Сумма</TableCell>
            <TableCell sx={{ background: theme.palette.secondary.main, width: '140px' }}>Цена за час</TableCell>
            <TableCell sx={{ background: theme.palette.secondary.main, minWidth: '140px' }}>Сумма</TableCell>
            <TableCell sx={{ background: theme.palette.secondary.main, width: '200px' }}>Субподрядчик</TableCell>
            <TableCell sx={{ minWidth: '140px' }}>Прибыль в час</TableCell>
            <TableCell sx={{ minWidth: '140px' }}>Прибыль всего</TableCell>
            <TableCell sx={{ minWidth: '140px' }}>Рентабельность</TableCell>
          </TableRow>
        </TableHead>
      )
    },
    Row: function Row(props: IEstimateRowProps) {
      const { operation, setPrPrState, prPrState } = props

      const [state, setState] = React.useState({
        mode: 'view',
        changed: false,
        operation: operation,
        isDeleteConfirmOpen: false,
        delta: {
          humanHoursQuantity: 0,
          machineryHoursQuantity: 0,
          genContractPricePerHour: 0,
          genContractTotal: 0,
          subContractPricePerHour: 0,
          subContractTotal: 0,
          incomePerHour: 0,
          income: 0,
          rentability: 0,
        },
      } as IEstimateRowState)

      const prevDelta = usePrevState(state.delta) as unknown as IEstimateRowState['delta']

      let timeout: NodeJS.Timeout

      React.useEffect(() => {
        state.changed && applyDiffOperationDeltaToEstimateTotal(getDiffBetweenOperationDeltas(prevDelta, state.delta))
      }, [state.delta])

      React.useEffect(() => {
        if (state.changed)
          timeout = setTimeout(
            () => recalculateDeltaBetweenInitialAndChangedOperation(operation, state.operation, state.delta),
            300,
          )
        return () => {
          clearTimeout(timeout)
        }
      }, [state.operation])

      const history = useHistory()

      function updateOperation() {
        ProductionProgramAPI.updateOperation(
          state.operation,
          // {
          //     ...qs.parse(history.location.search, { ignoreQueryPrefix: true }) as unknown as IProductionProgramEstimateGetListQuery,
          //     analysisType: "PLAN"
          // },
        )
      }

      function recalculateLaboriousOperation(
        value: string | number | boolean | undefined,
        key: keyof IPrPrEstimateOperation,
        operation: IPrPrEstimateOperation,
      ) {
        // @ts-ignore
        let copy = getDeepCopy(operation)
        copy[key] = value

        function calculate(value: Array<keyof IPrPrEstimateOperation>) {
          let totalHoursQty = (Number(copy.humanHoursQuantity) || 0) + (Number(copy.machineryHoursQuantity) || 0)
          if (value.includes('genContractPricePerHour')) {
            copy.genContractPricePerHour = copy.genContractTotal / totalHoursQty
          }
          if (value.includes('subContractPricePerHour')) {
            copy.subContractPricePerHour = totalHoursQty > 0 ? copy.subContractTotal / totalHoursQty : 0
          }
          if (value.includes('incomePerHour')) {
            copy.incomePerHour = Number(copy.income) / totalHoursQty
          }
        }
        switch (key) {
          case 'humanHoursQuantity':
            calculate(['genContractPricePerHour', 'subContractPricePerHour', 'incomePerHour'])
            break
          case 'machineryHoursQuantity':
            calculate(['genContractPricePerHour', 'subContractPricePerHour', 'incomePerHour'])
            break
        }
        // let copy = getDeepCopy(operation)
        setState((prevState) => ({
          ...prevState,
          operation: copy,
          changed: true,
        }))
      }

      function changeOperationStringValue(key: keyof Partial<IPrPrEstimateOperation>, value: string) {
        setState((prevState) => ({
          ...prevState,
          operation: {
            ...state.operation,
            [key]: value,
          },
        }))
      }

      function recalculateDeltaBetweenInitialAndChangedOperation(
        propsOperation: IPrPrEstimateOperation,
        stateOperation: IPrPrEstimateOperation,
        delta: IEstimateRowState['delta'],
      ) {
        let deltaCopy = delta && getDeepCopy(delta)
        for (let key in deltaCopy) {
          deltaCopy[key] =
            (stateOperation[key as keyof IPrPrEstimateTotal] || 0) -
            (propsOperation[key as keyof IPrPrEstimateTotal] || 0)
        }
        setState((prevState) => ({
          ...prevState,
          delta: deltaCopy,
        }))
      }

      function getDiffBetweenOperationDeltas(
        prevDelta: IEstimateRowState['delta'],
        newDelta: IEstimateRowState['delta'],
      ): IEstimateRowState['delta'] {
        let targetDelta = {} as IEstimateRowState['delta']
        for (let key in prevDelta) {
          targetDelta[key as keyof IEstimateRowState['delta']] =
            (newDelta[key as keyof IPrPrEstimateTotal] || 0) - (prevDelta[key as keyof IPrPrEstimateTotal] || 0)
        }
        return targetDelta
      }

      function applyDiffOperationDeltaToEstimateTotal(targetDelta: IEstimateRowState['delta']) {
        let totalCopy = prPrState.recalculatedTotal && getDeepCopy(prPrState.recalculatedTotal)
        let averageValues = ['genContractPricePerHour', 'subContractPricePerHour', 'incomePerHour']
        for (let key in totalCopy) {
          if (averageValues.includes(key)) {
            let operationsArrLength = prPrState.totalRecords
            let average = totalCopy[key as keyof IEstimateRowState['delta']] || 0
            let delta = targetDelta[key as keyof IEstimateRowState['delta']] || 0
            let sum = average * operationsArrLength
            let newSum = sum + delta
            totalCopy[key as keyof IEstimateRowState['delta']] = newSum / operationsArrLength
          } else {
            totalCopy[key as keyof IEstimateRowState['delta']] +=
              targetDelta[key as keyof IEstimateRowState['delta']] || 0
          }
        }
        if (totalCopy) {
          totalCopy['rentability'] = totalCopy['genContractTotal']
            ? ((totalCopy['income'] || 0) / totalCopy['genContractTotal']) * 100
            : 0
        }
        setPrPrState((prevState) => ({
          ...prevState,
          recalculatedTotal: totalCopy,
        }))
      }

      return (
        <EETableRowProvider onSwitchMode={(prevMode, currentMode) => prevMode == 'edit' && updateOperation()}>
          <EETableRowContext.Consumer>
            {(value) => (
              <>
                <TableCell />
                <TableCell>
                  <EEString
                    align="left"
                    name="name"
                    mode={'view'}
                    value={state.operation.name || ''}
                    onChange={(e) => changeOperationStringValue('name', e.target.value)}
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <EENumber
                    name="humanHoursQuantity"
                    mode={value.mode}
                    value={state.operation.humanHoursQuantity || 0}
                    onNumberChange={(values) =>
                      recalculateLaboriousOperation(values.floatValue, 'humanHoursQuantity', state.operation)
                    }
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    /* NumberFormatProps={{
                                            decimalScale: 0,
                                        }} */
                  />
                </TableCell>
                <TableCell>
                  <EENumber
                    name="machineryHoursQuantity"
                    mode={value.mode}
                    value={state.operation.machineryHoursQuantity || 0}
                    onNumberChange={(values) =>
                      recalculateLaboriousOperation(values.floatValue, 'machineryHoursQuantity', state.operation)
                    }
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    /*  NumberFormatProps={{
                                            decimalScale: 0,
                                        }} */
                  />
                </TableCell>
                <TableCell sx={{ borderLeft: `1px solid ${theme.palette.grey[300]}` }}>
                  <EENumber
                    name="genContractPricePerHour"
                    mode={'view'}
                    value={state.operation.genContractPricePerHour || 0}
                    onNumberChange={(values) =>
                      recalculateLaboriousOperation(values.floatValue, 'genContractPricePerHour', state.operation)
                    }
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    /* NumberFormatProps={{
                                            decimalScale: 0,
                                        }} */
                  />
                </TableCell>
                <TableCell>
                  <EENumber
                    name="genContractTotal"
                    mode={'view'}
                    value={state.operation.genContractTotal || 0}
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    /*  NumberFormatProps={{
                                            decimalScale: 0,
                                        }} */
                  />
                </TableCell>
                <TableCell sx={{ borderLeft: `1px solid ${theme.palette.grey[300]}` }}>
                  <EENumber
                    name="subContractPricePerHour"
                    mode={'view'}
                    value={state.operation.subContractPricePerHour || 0}
                    onNumberChange={(values) =>
                      recalculateLaboriousOperation(values.floatValue, 'subContractPricePerHour', state.operation)
                    }
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    /*  NumberFormatProps={{
                                            decimalScale: 0,
                                        }} */
                  />
                </TableCell>
                <TableCell>
                  <EENumber
                    name="subContractTotal"
                    mode={'view'}
                    value={state.operation.subContractTotal || 0}
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    /*  NumberFormatProps={{
                                            decimalScale: 0,
                                        }} */
                  />
                </TableCell>
                <TableCell sx={{ borderRight: `1px solid ${theme.palette.grey[300]}` }}>
                  <EEString
                    name="subContractContractor"
                    mode={'view'}
                    value={state.operation.subContractContractor || ''}
                    onChange={(e) => changeOperationStringValue('subContractContractor', e.target.value)}
                    viewModeStyle={{ ...tableCellInput_ViewMode, justifyContent: 'center' }}
                    TextFieldProps={{
                      sx: { ...tableCellInput, '& input': { ...tableCellInput['input'], textAlign: 'center' } },
                    }}
                  />
                </TableCell>
                <TableCell>
                  <EENumber
                    name="incomePerHour"
                    mode={'view'}
                    value={state.operation.incomePerHour || 0}
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    /*  NumberFormatProps={{
                                            decimalScale: 0,
                                        }} */
                  />
                </TableCell>
                <TableCell>
                  <EENumber
                    name="income"
                    mode={'view'}
                    value={state.operation.income || 0}
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    NumberFormatProps={{
                      allowNegative: true,
                      // decimalScale: 0,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <EENumber
                    name="rentability"
                    mode={'view'}
                    value={state.operation.rentability || 0}
                    viewModeStyle={tableCellInput_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInput,
                    }}
                    NumberFormatProps={{
                      allowNegative: true,
                      suffix: ' %',
                    }}
                  />
                </TableCell>
              </>
            )}
          </EETableRowContext.Consumer>
        </EETableRowProvider>
      )
    },
    Total: function Total(props: IEstimateTotalProps) {
      const { total } = props

      return (
        <TableFooter sx={{ ...table.footer.sticky, bottom: -2 }}>
          <TableRow>
            <TableCell width="44px" />
            <TableCell sx={{ paddingX: 2 }}>ВСЕГО</TableCell>
            <TableCell align="center">
              <EENumber
                name="humanHoursQuantity"
                mode={'view'}
                value={total.humanHoursQuantity || 0}
                viewModeStyle={tableCellInput_ViewMode}
                TextFieldProps={{
                  sx: tableCellInput,
                }}
                /*  NumberFormatProps={{
                                    decimalScale: 0,
                                }} */
              />
            </TableCell>
            <TableCell align="center">
              <EENumber
                name="machineryHoursQuantity"
                mode={'view'}
                value={total.machineryHoursQuantity || 0}
                viewModeStyle={tableCellInput_ViewMode}
                TextFieldProps={{
                  sx: tableCellInput,
                }}
                /*  NumberFormatProps={{
                                    decimalScale: 0,
                                }} */
              />
            </TableCell>
            <TableCell align="center">
              <EENumber
                name="genContractPricePerHour"
                mode={'view'}
                value={total.genContractPricePerHour || 0}
                viewModeStyle={tableCellInput_ViewMode}
                TextFieldProps={{
                  sx: tableCellInput,
                }}
                /*   NumberFormatProps={{
                                    decimalScale: 0,
                                }} */
              />
            </TableCell>
            <TableCell align="center">
              <EENumber
                name="genContractTotal"
                mode={'view'}
                value={total.genContractTotal || 0}
                viewModeStyle={tableCellInput_ViewMode}
                TextFieldProps={{
                  sx: tableCellInput,
                }}
                /*  NumberFormatProps={{
                                    decimalScale: 0,
                                }} */
              />
            </TableCell>
            <TableCell align="center">
              <EENumber
                name="subContractPricePerHour"
                mode={'view'}
                value={total.subContractPricePerHour || 0}
                viewModeStyle={tableCellInput_ViewMode}
                TextFieldProps={{
                  sx: tableCellInput,
                }}
                /*  NumberFormatProps={{
                                    decimalScale: 0,
                                }} */
              />
            </TableCell>
            <TableCell align="center">
              <EENumber
                name="subContractTotal"
                mode={'view'}
                value={total.subContractTotal || 0}
                viewModeStyle={tableCellInput_ViewMode}
                TextFieldProps={{
                  sx: tableCellInput,
                }}
              />
            </TableCell>
            <TableCell />
            <TableCell align="center">
              <EENumber
                name="incomePerHour"
                mode={'view'}
                value={total.incomePerHour || 0}
                viewModeStyle={tableCellInput_ViewMode}
                TextFieldProps={{
                  sx: tableCellInput,
                }}
                /*  NumberFormatProps={{
                                    decimalScale: 0,
                                }} */
              />
            </TableCell>
            <TableCell align="center">
              <EENumber
                name="income"
                mode={'view'}
                value={total.income || 0}
                viewModeStyle={tableCellInput_ViewMode}
                TextFieldProps={{
                  sx: tableCellInput,
                }}
                NumberFormatProps={{
                  allowNegative: true,
                  // decimalScale: 0,
                }}
              />
            </TableCell>
            <TableCell align="center">
              <EENumber
                name="rentability"
                mode={'view'}
                value={total.rentability || 0}
                viewModeStyle={tableCellInput_ViewMode}
                TextFieldProps={{
                  sx: tableCellInput,
                }}
                NumberFormatProps={{
                  allowNegative: true,
                  suffix: ' %',
                }}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      )
    },
  },
}

function validateRow(operation: IPrPrEstimateOperation): IValidationErrors {
  let validationErrors = {} as IValidationErrors
  if (operation.name == '') {
    validationErrors.name = {
      isValid: false,
      message: 'Поле "Наименование работ" - обязательное поле',
    }
  } else if (operation.name && operation.name.length > 200) {
    validationErrors.name = {
      isValid: false,
      message: 'Поле "Наименование работ" - не более 200 символов',
    }
  } else {
    validationErrors.name = {
      isValid: true,
      message: '',
    }
  }
  if (operation.unitType == '') {
    validationErrors.unitType = {
      isValid: false,
      message: 'Поле "Ед. изм." - обязательное поле',
    }
  } else if (operation.unitType && operation.unitType.length > 20) {
    validationErrors.unitType = {
      isValid: false,
      message: 'Поле "Ед. изм." - не более 20 символов',
    }
  } else {
    validationErrors.unitType = {
      isValid: true,
      message: '',
    }
  }

  // else {
  //     validationErrors.name = {
  //         isValid: true,
  //         message: ""
  //     }
  // }
  return validationErrors
}
