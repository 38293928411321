import NiceModal from '@ebay/nice-modal-react'
import { Add, Downloading, ExpandMore, PlaylistAdd, Search } from '@mui/icons-material'
import { Box, Button, CircularProgress, MenuItem, Stack, Tab, Tabs, TextField, Typography } from '@mui/material'
import { UploadFileDrawer } from 'components/Drawers/UploadFileDrawer/UploadFileDrawer'
import qs from 'qs'
import React, { useContext, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useGetCompanyQuery } from '../../api/profile'
import { ModalSwitcher } from '../../components/_NEW/ModalSwitcher/ModalSwitcher'
import { ButtonGroupCustom } from '../../components/ButtonGroupCustom/ButtonGroupCustom'
import { ControlPanel } from '../../components/ControlPanel/ControlPanel'
import { MyModal } from '../../components/MyModal/MyModal'
import { InterfaceContext } from '../../contexts/context.interface'
import { MappingArticlesAPI } from '../../global/api/APIMethods/MappingArticlesAPI'
import { changeQueryParams, getQueryParam } from '../../global/functions'
import { paginationSelect } from '../../global/styles/presets'
import { theme } from '../../global/styles/theme'
import { ProjectType, TBudgetType } from '../../global/types/commos-def'
import { IMappingArticleQuerySchema, MappingArticles } from './subPages/MappingArticles/MappingArticles'
import { SearchArticles } from './subPages/SearchArticles/SearchArticles'

const SUB_PAGES = [
  {
    title: 'МЭППИНГ СТАТЕЙ',
    queryParamValue: 'MappingArticles',
    component: MappingArticles,
  },
  {
    title: 'Поиск связей',
    queryParamValue: 'SearckLinks',
    component: SearchArticles,
  },
]

export interface IMappingPageQuerySchema extends IMappingArticleQuerySchema {
  subPage: 'MappingArticles' | 'SearckLinks' | 'ReportTreeForm'
  budgetType: TBudgetType
}

export type TControlBtn = 'addArticleBtn' | 'subBillAssignBtn'

export interface IControlBtnState {
  clicked: boolean
  hidden: boolean
  disabled: boolean
}

interface ISearch {
  value: string
  disabled: boolean
}

type IControlsState = {
  [key in TControlBtn]: IControlBtnState
}

export interface IMappingPageSubPageProps {
  controlsState: IControlsState & { search: ISearch }
  setControlsState: React.Dispatch<React.SetStateAction<IMappingPageSubPageProps['controlsState']>>
  budgetType: ProjectType
  isRefetch?: boolean
}

export function MappingPageNew({ budgetType }: { budgetType: ProjectType }) {
  const history = useHistory()

  const location = useLocation()

  const interfaceCTX = useContext(InterfaceContext)

  const [isLoading, setIsLoading] = React.useState(false)
  const [isRefetch, setIsRefetch] = useState(false)

  const { data: company } = useGetCompanyQuery()

  const [controlsState, setControlsState] = React.useState({
    addArticleBtn: {
      clicked: false,
      hidden: true,
      disabled: false,
    },
    subBillAssignBtn: {
      clicked: false,
      hidden: true,
      disabled: false,
    },
    search: {
      value: '',
      disabled: true,
    },
  } as unknown as IMappingPageSubPageProps['controlsState'])

  let queryParams = qs.parse(location.search, { ignoreQueryPrefix: true }) as unknown as IMappingPageQuerySchema

  React.useEffect(() => {
    initiateBreadcrumbs()
  }, [])

  React.useEffect(() => {
    queryParams.subPage == 'SearckLinks' &&
      setControlsState((prevState) => ({
        ...prevState,
        addArticleBtn: {
          ...prevState.addArticleBtn,
          hidden: true,
        },
        subBillAssignBtn: {
          ...prevState.subBillAssignBtn,
          hidden: true,
        },
      }))
  }, [location])

  React.useEffect(() => {
    // console.log(queryParams)
    if (getQueryParam(history, 'subPage') == undefined) {
      changeQueryParams(history, {
        budgetType: 'bdr',
        subPage: 'MappingArticles',
      })
    }
  }, [location])

  function initiateBreadcrumbs() {
    interfaceCTX.setBreadcrumbsProps([
      {
        variant: 'title',
        title: `Мэппинг статей`,
      },
    ])
  }

  function registerBtnEvent(btn: 'addArticleBtn' | 'subBillAssignBtn', newBtnState: Partial<IControlBtnState>) {
    if (btn == 'subBillAssignBtn') {
      changeQueryParams(history, {
        filledWith: 'subBills',
      })
    }
    setControlsState((prevState) => ({
      ...prevState,
      [btn]: {
        ...prevState[btn],
        ...newBtnState,
      },
    }))
  }

  function getActiveSubPage(props: IMappingPageSubPageProps, queryParams: IMappingPageQuerySchema) {
    if (queryParams.subPage) {
      let TargetComponent = SUB_PAGES.filter((subPage) => subPage.queryParamValue == queryParams.subPage)[0].component

      return <TargetComponent {...props} />
    }
    return null
  }

  function resetAndApplyMappingTemplate(switchIsOpen: () => void) {
    setIsLoading((v) => true)
    Promise.all([MappingArticlesAPI.resetTemplate('bdr'), MappingArticlesAPI.applyTemplate('bdr')])
      .then(() => {
        setIsLoading((v) => false)
        switchIsOpen()
      })
      .catch((e) => {
        setIsLoading((v) => false)
      })
  }

  const uploadFileDrawerClickHandler = () => {
    NiceModal.show(UploadFileDrawer, { budget: queryParams.budgetType }).then(() => setIsRefetch((prev) => !prev))
  }

  return (
    <Box>
      <ControlPanel.Wrapper>
        <ControlPanel.InnerContainer align="left" mr={0} sx={{ width: '100%', justifyContent: 'space-between' }}>
          <Stack direction="row">
            <TextField
              select
              SelectProps={{
                IconComponent: (props) => <ExpandMore {...props} />,
              }}
              defaultValue={getQueryParam(history, 'budgetType') || 'bdr'}
              onChange={(e) =>
                changeQueryParams(history, {
                  budgetType: e.target.value,
                  currentArticleID: undefined,
                  BDRArticleType: undefined,
                  filledWith: undefined,
                })
              }
              sx={{
                ...paginationSelect,
                minWidth: '78px',
                '.MuiSelect-select': {
                  ...paginationSelect['.MuiSelect-select'],
                  textAlign: 'left',
                  pl: 0,
                },
              }}>
              <MenuItem key={'bdr'} value={'bdr'}>
                {company?.userCompanyName === 'Черное море' ? 'Бюджет' : budgetType === 'GEN' ? 'БДР' : 'Капвложения'}
              </MenuItem>
              <MenuItem key={'bdds'} value={'bdds'}>
                БДДС
              </MenuItem>
            </TextField>
            <Tabs
              value={getQueryParam(history, 'subPage')}
              onChange={(e, value) =>
                changeQueryParams(history, {
                  subPage: value,
                })
              }>
              {SUB_PAGES.map((subPage) => {
                if (subPage.title === 'Поиск связей' && budgetType === 'INVEST') return undefined
                return <Tab key={subPage.title} label={subPage.title} value={subPage.queryParamValue} />
              })}
            </Tabs>
          </Stack>
        </ControlPanel.InnerContainer>
        {getQueryParam(history, 'subPage') === 'SearckLinks' && (
          <ControlPanel.InnerContainer align="left" sx={{ mr: 0, width: '100%' }}>
            <TextField
              disabled={controlsState.search.disabled}
              // width={600}
              fullWidth
              value={controlsState.search.value}
              InputProps={{
                startAdornment: <Search color="disabled" sx={{ mr: 1 }} />,
              }}
              sx={{
                '.MuiInputBase-root': {
                  paddingLeft: `${theme.spacing(1)} !important`,
                  // mr: 60
                },
                ml: theme.spacing(2.5),
                background: 'white',
                input: {
                  paddingY: theme.spacing(0.75),
                },
                // width: 600
              }}
              size="small"
              onChange={(e) =>
                setControlsState((prevState) => ({
                  ...prevState,
                  search: {
                    ...prevState.search,
                    value: e.target.value,
                  },
                }))
              }
            />
          </ControlPanel.InnerContainer>
        )}

        <ControlPanel.InnerContainer align="right" gap={2}>
          {getQueryParam(history, 'subPage') === 'MappingArticles' && (
            <>
              <Button
                size="small"
                variant="outlined"
                onClick={uploadFileDrawerClickHandler}
                startIcon={<Downloading />}>
                Загрузить
              </Button>
              <ModalSwitcher
                disableCloseBtn
                closeDisabled={isLoading}
                ModalContent={(props: any) => {
                  return (
                    <Box display="flex" flexDirection="column" maxWidth={400}>
                      <MyModal.Title>Вы уверены, что хотите перезаписать субсчета?</MyModal.Title>
                      <MyModal.Description style={{ textAlign: 'justify' }}>
                        Применяя шаблон, все ранее созданные субсчета будут удалены. Вместе с этим удалятся все связи
                        субсчетов с формой БДР и операциями бюджетов/производственных программ.
                        <br />
                        <br />
                        <Typography>
                          <b>Данное действие не обратимо</b>
                        </Typography>
                      </MyModal.Description>
                      <ButtonGroupCustom>
                        <Button
                          disabled={isLoading}
                          variant="contained"
                          color="success"
                          size="medium"
                          onClick={(e) => resetAndApplyMappingTemplate(props.switchIsOpen)}>
                          {isLoading && <CircularProgress sx={{ color: theme.palette.disabled, p: 1 }} />}
                          {!isLoading && 'Перезаписать'}
                        </Button>
                        <Button
                          disabled={isLoading}
                          variant="contained"
                          size="medium"
                          color="primary"
                          onClick={(e) => props.switchIsOpen()}>
                          Отменить
                        </Button>
                      </ButtonGroupCustom>
                    </Box>
                  )
                }}
              />
            </>
          )}
          {!controlsState?.addArticleBtn?.hidden && (
            <Button
              onClick={(e) =>
                registerBtnEvent('addArticleBtn', {
                  clicked: true,
                })
              }
              size="small"
              variant="contained"
              color="primary"
              startIcon={<Add />}>
              Добавить статью
            </Button>
          )}
          {!controlsState?.subBillAssignBtn?.hidden && (
            <ControlPanel.Btn
              onClick={(e) =>
                registerBtnEvent('subBillAssignBtn', {
                  clicked: true,
                })
              }
              size="small"
              variant="outlined"
              color="primary"
              startIcon={<PlaylistAdd />}>
              Назначить субсчета
            </ControlPanel.Btn>
          )}
        </ControlPanel.InnerContainer>
      </ControlPanel.Wrapper>

      <Box>
        <Box mr={1}>
          {getActiveSubPage(
            {
              controlsState: controlsState,
              setControlsState: setControlsState,
              budgetType,
              isRefetch,
            },
            queryParams,
          )}
        </Box>
      </Box>
    </Box>
  )
}
