import React from 'react'
import { Close, Help } from '@mui/icons-material'
import { Autocomplete, AutocompleteProps, Box, Button, SxProps, Tab, TableCell, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ControlPanel } from '../ControlPanel/ControlPanel'
import { SubBillAssign, SUBBILL_ASSIGN_USER_GUIDE_STEPS } from '../SubBillAssign/SubBillAssign'
import { AccountantAPI } from '../../global/api/APIMethods/AccountantAPI'
import { changeQueryParams, getDeepCopy, getQueryParam } from '../../global/functions'
import { autocomplete, outlinedTab, outlinedTabs, scrollableWrapper, STYLED } from '../../global/styles/presets'
import { theme } from '../../global/styles/theme'
import {
  IFinCenterOperation,
  IPrPrEstimateOperation,
  IReportRelationshipItem,
  TBudgetType,
  TEstimateContractType,
} from '../../global/types/commos-def'
import { useHistory, useLocation } from 'react-router-dom'
import { UserGuide } from '../UserGuide/UserGuide'

export type TSubBillAssignOperation = IFinCenterOperation | IPrPrEstimateOperation

interface isSubBillAssignModeProps {
  operationType: 'finCenter' | 'productionProgram'
  subBillAssignOperationRowCells?: (operation: TSubBillAssignOperation) => React.ReactNode | React.ReactNode[]
  budgetType: TBudgetType
  operations: TSubBillAssignOperation[]
  operationsTableSxProps?: SxProps
  additionalControls?: React.ReactNode | React.ReactNode[]
  autocompleteOptions: IReportRelationshipItem[]
  AutocompleteProps?: Partial<AutocompleteProps<IReportRelationshipItem, undefined, undefined, undefined>>
  open: boolean
  onClose: () => void
}

interface isSubBillAssignModeState {
  operations: TSubBillAssignOperation[]
  activeRelationshipItem: IReportRelationshipItem | null
  isUserGuideOpen: boolean
}

export function SubBillAssignMode(props: isSubBillAssignModeProps) {
  const {
    additionalControls,
    operationType,
    subBillAssignOperationRowCells,
    operations,
    operationsTableSxProps,
    open,
    onClose,
    budgetType,
    autocompleteOptions,
    AutocompleteProps,
  } = props

  // console.log('autocompleteOptions: ', autocompleteOptions);
  const [state, setState] = React.useState({
    operations: operations,
    activeRelationshipItem: null,
    isUserGuideOpen: false,
  } as isSubBillAssignModeState)

  const history = useHistory()

  const location = useLocation()

  const { t } = useTranslation('ENUMS')

  React.useEffect(() => {
    // console.log('asdasdasda');
    setState((prevState) => ({
      ...prevState,
      activeRelationshipItem: null,
    }))
  }, [location.search])

  function switchisUserGuideOpenOpen() {
    setState((prevState) => ({
      ...prevState,
      isUserGuideOpen: !prevState.isUserGuideOpen,
    }))
  }

  function setActiveSubBill(value: IReportRelationshipItem | null) {
    // console.log('value: ', value);

    setState((prevState) => ({
      ...prevState,
      activeRelationshipItem: value,
    }))
  }

  function subBillToOperation(
    operationIndex: number,
    type: 'link' | 'unlink',
    budgetType: TBudgetType,
    operationType: 'finCenter' | 'productionProgram',
  ) {
    if (!operationType || operationType == 'finCenter') {
      if (state.operations) {
        AccountantAPI.subBillToOperation(budgetType, type, {
          data: [
            {
              budgetRecordID: state.operations![operationIndex].id as number,
              subBillID: state.activeRelationshipItem?.subBill.id as number,
            },
          ],
        })
        let copy = getDeepCopy(state.operations)
        copy[operationIndex].relationships = type == 'link' ? state.activeRelationshipItem : null
        setState((prevState) => ({
          ...prevState,
          operations: copy,
        }))
      }
    } else {
      let copy = getDeepCopy(state.operations) as IPrPrEstimateOperation[]
      if (type == 'link') {
        copy[operationIndex].relationships[getQueryParam(history, 'contractType') as TEstimateContractType] =
          state.activeRelationshipItem && state.activeRelationshipItem
      } else {
        copy[operationIndex].relationships[getQueryParam(history, 'contractType') as TEstimateContractType] = null
      }

      let requestBody = {
        estimateID: state.operations[operationIndex].id as number,
        genContractSubBillID: copy[operationIndex].relationships.genContract?.subBill.id || null,
        subContractSubBillID: copy[operationIndex].relationships.subContract?.subBill.id || null,
      }

      AccountantAPI.subBillToPrPrOperation(type, requestBody)

      setState((prevState) => ({
        ...prevState,
        operations: copy,
      }))
    }
  }

  return (
    <Box>
      <UserGuide
        open={state.isUserGuideOpen}
        onClose={switchisUserGuideOpenOpen}
        steps={SUBBILL_ASSIGN_USER_GUIDE_STEPS}
      />
      <ControlPanel.Wrapper>
        {additionalControls}
        {operationType == 'productionProgram' && (
          <ControlPanel.InnerContainer align="left" sx={{ mr: 2 }}>
            <Tabs
              sx={STYLED.TABS.OUTLINED}
              value={getQueryParam(history, 'contractType')}
              onChange={(e, value: string) =>
                changeQueryParams(history, {
                  contractType: value,
                })
              }
            >
              <Tab label="ГЕНПОДРЯД" value="genContract"></Tab>
              <Tab label="СУБПОДРЯД" value="subContract"></Tab>
            </Tabs>
          </ControlPanel.InnerContainer>
        )}
        <ControlPanel.InnerContainer align="left" sx={{ width: '50%', position: 'relative' }}>
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            // disablePortal
            fullWidth
            options={autocompleteOptions}
            sx={autocomplete.small}
            value={state.activeRelationshipItem}
            onChange={(event, value) => setActiveSubBill(value)}
            noOptionsText={'Нет данных'}
            // groupBy={(option) => getQueryParam(history, "budgetType") == "bdr" ? t(`BDR_ARTICLE_CATEGORY.` + String(option.articles[0].type)) : option.articles[0].name}
            renderOption={(props, option) => (
              <SubBillAssign.AutocompleteOptionWrapper optionProps={props}>
                <SubBillAssign.AutocompleteOptionItem width="50%">{option.label}</SubBillAssign.AutocompleteOptionItem>
                <SubBillAssign.AutocompleteOptionItem width="25%">
                  {option.gostBill.number + ' ' + option.gostBill.name}
                </SubBillAssign.AutocompleteOptionItem>
                <SubBillAssign.AutocompleteOptionItem width="25%">
                  {option.articles[1]?.name}
                </SubBillAssign.AutocompleteOptionItem>
              </SubBillAssign.AutocompleteOptionWrapper>
            )}
            renderInput={(props) => <SubBillAssign.AutocompleteTextfield {...props} />}
            PopperComponent={(props) => <SubBillAssign.AutocompletePopper {...props} />}
            PaperComponent={(props) => {
              return (
                <SubBillAssign.AutocompletePaper>
                  <SubBillAssign.AutocompleteOptionHeadings>
                    <SubBillAssign.AutocompleteOptionHeadingItem width="50%">
                      Наименование субсчета
                    </SubBillAssign.AutocompleteOptionHeadingItem>
                    <SubBillAssign.AutocompleteOptionHeadingItem width="25%">
                      Наименование счета
                    </SubBillAssign.AutocompleteOptionHeadingItem>
                    <SubBillAssign.AutocompleteOptionHeadingItem width="25%">
                      Статья {t('BUDGET_TYPE.' + budgetType)}
                    </SubBillAssign.AutocompleteOptionHeadingItem>
                  </SubBillAssign.AutocompleteOptionHeadings>
                  {props.children}
                </SubBillAssign.AutocompletePaper>
              )
            }}
            {...AutocompleteProps}
          />
          {/* } */}

          <Button
            size="small"
            sx={{
              ml: 2,
            }}
            onClick={switchisUserGuideOpenOpen}
          >
            <Help sx={{ color: theme.palette.primary.light }} />
          </Button>
        </ControlPanel.InnerContainer>

        <ControlPanel.InnerContainer align="right">
          <Button variant="outlined" size="small" startIcon={<Close />} onClick={onClose}>
            Закрыть
          </Button>
        </ControlPanel.InnerContainer>
      </ControlPanel.Wrapper>
      <SubBillAssign.TableWrapper sx={{ p: 0, ...operationsTableSxProps }}>
        <SubBillAssign.Headings>
          <TableCell sx={{ textAlign: 'left', width: '55%' }}>Наименование операции</TableCell>
          <TableCell sx={{ textAlign: 'left', width: '30%' }}>Наименование субсчета</TableCell>
          <TableCell sx={{ textAlign: 'left', width: '15%' }}>Наименование счета</TableCell>
        </SubBillAssign.Headings>

        <SubBillAssign.Body>
          {state.operations &&
            state.operations.map((operation, index) => {
              let isSubBillAssigned: boolean
              // console.log(operation);
              if (operationType == 'finCenter') {
                isSubBillAssigned = !!operation.relationships
              } else {
                isSubBillAssigned = !!(operation as IPrPrEstimateOperation).relationships?.[
                  getQueryParam(history, 'contractType') as TEstimateContractType
                ]?.subBill
              }
              return (
                <SubBillAssign.RecordRow
                  key={operation.id}
                  activeRelationshipItem={state.activeRelationshipItem}
                  operation={operation}
                  isSubBillToAssignChosen={!!state.activeRelationshipItem}
                  isSubbillAssigned={isSubBillAssigned}
                  linkSubBill={() => subBillToOperation(index, 'link', budgetType, operationType)}
                  unLinkSuBBill={() => subBillToOperation(index, 'unlink', budgetType, operationType)}
                >
                  {!subBillAssignOperationRowCells && (
                    <>
                      <TableCell>{operation.name || ''}</TableCell>
                      <TableCell>{(operation.relationships as IReportRelationshipItem)?.subBill.name || ''}</TableCell>
                      <TableCell>
                        {operation.relationships
                          ? (operation.relationships as IReportRelationshipItem)?.gostBill.number +
                            ' ' +
                            (operation.relationships as IReportRelationshipItem)?.gostBill.name
                          : ''}
                      </TableCell>
                    </>
                  )}
                  {subBillAssignOperationRowCells && subBillAssignOperationRowCells(operation)}
                </SubBillAssign.RecordRow>
              )
            })}
        </SubBillAssign.Body>
      </SubBillAssign.TableWrapper>
    </Box>
  )
}
