import { FormControl, InputLabel, MenuItem, Stack } from '@mui/material'
import { useFormikContext } from 'formik'
import cloneDeep from 'lodash/cloneDeep'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { changeQueryParams } from '../../global/functions'
import { TMonthName, TQuarterName, TReportType } from '../../global/types/commos-def'
import { Params } from '../../global/types/params-def'
import { MONTHS, QUARTERS } from '../../global/variables'
import {
  IBdrParametersFormData,
  IBdrPeriod,
} from '../../pages/ReportPage/components/BdrParametersDrawer/components/BdrParametersDrawerForm/BdrParametersDrawerForm.types'
import { bdrParametersSelector } from '../../store/slices/bdrParameters/selectors'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { ControlPanel } from '../ControlPanel/ControlPanel'

export interface IBudgetControlsQueryParamsScheme {
  reportType: TReportType
  monthStart: TMonthName
  monthEnd: TMonthName
  yearStart: string
  yearEnd: string
}

interface IBudgetControlsProps {
  variant: 'finCenter' | 'report'
  availableYears: Array<string | number>
  periodId: number
  disabled?: boolean
}

export function BdrBudgetControls(props: IBudgetControlsProps) {
  const { variant, availableYears, periodId, disabled } = props
  const dispatch = useAppDispatch()
  const bdrParameters = useTypedSelector(bdrParametersSelector)
  const history = useHistory()
  const { values, setFieldValue } = useFormikContext<IBdrParametersFormData>()
  const currentReportType = values.periodType

  const { financeCenterID } = useParams() as Params

  const { t } = useTranslation('ENUMS')

  React.useEffect(() => {
    let defaultQuery = {
      reportType: 'MONTHLY',
      // monthStart: 'JANUARY',
      // monthEnd: 'DECEMBER',
      yearStart: availableYears[0],
      yearEnd: availableYears[0],
    }
    !history.location.search.includes('reportType') && changeQueryParams(history, defaultQuery)
  }, [history.location.search])

  const currentPeriodById = useMemo(() => {
    return values.periods.filter((period) => period.id === periodId)[0]
  }, [values.periods])

  function getMonthOptions(_: 'from' | 'to') {
    return MONTHS.map((month) => {
      return (
        <MenuItem key={month} value={month}>
          {t('MONTHS_ABBR.' + month)}
        </MenuItem>
      )
    })
  }

  function getQuarterOptions(_: 'from' | 'to') {
    return QUARTERS.map((month) => {
      return (
        <MenuItem key={month} value={month}>
          {t('QUARTERS.' + month)}
          {/* {t('MONTHS_ABBR.' + month)} */}
        </MenuItem>
      )
    })
  }

  function getYearOptions() {
    return availableYears.map((year) => {
      return (
        <MenuItem key={year} value={year}>
          {year}
        </MenuItem>
      )
    })
  }

  function changeMonthRange(range: 'start' | 'end', value: string) {
    let currentPeriod = cloneDeep(currentPeriodById)
    currentPeriod[range].month = value as TMonthName

    const yearStart = currentPeriod.start.year
    const yearEnd = currentPeriod.end.year

    if (yearStart === yearEnd) {
      const monthStartIndex = MONTHS.findIndex((m) => m === currentPeriod.start.month)
      const monthEndIndex = MONTHS.findIndex((m) => m === currentPeriod.end.month)

      if (range === 'start' && monthStartIndex > monthEndIndex) {
        currentPeriod.end.month = value as TMonthName
      }
      if (range === 'end' && monthStartIndex > monthEndIndex) {
        currentPeriod.start.month = value as TMonthName
      }
    }

    editPeriodValue(currentPeriod)
  }

  function changeQuarterRange(range: 'start' | 'end', value: TQuarterName) {
    let currentPeriod = cloneDeep(currentPeriodById)
    currentPeriod[range].quarter = value as TQuarterName

    const yearStart = currentPeriod.start.year
    const yearEnd = currentPeriod.end.year

    if (yearStart === yearEnd) {
      const quarterStartIndex = QUARTERS.findIndex((m) => m === currentPeriod.start.quarter)
      const quarterEndIndex = QUARTERS.findIndex((m) => m === currentPeriod.end.quarter)

      if (range === 'start' && quarterStartIndex > quarterEndIndex) {
        currentPeriod.end.quarter = value as TQuarterName
      }
      if (range === 'end' && quarterStartIndex > quarterEndIndex) {
        currentPeriod.start.quarter = value as TQuarterName
      }
    }

    editPeriodValue(currentPeriod)
  }

  function changeYearRange(range: 'start' | 'end', value: string) {
    let currentPeriod = cloneDeep(currentPeriodById)
    currentPeriod[range].year = Number(value)

    const yearStart = currentPeriod.start.year
    const yearEnd = currentPeriod.end.year

    if (range === 'start' && yearStart > yearEnd) {
      currentPeriod.end.year = yearStart
    }

    if (range === 'end' && yearStart > yearEnd) {
      currentPeriod.start.year = yearEnd
    }

    const reportType = values.periodType

    if (currentPeriod.start.year === currentPeriod.end.year && reportType !== 'YEARLY') {
      if (reportType === 'MONTHLY') {
        const monthStart = currentPeriod.start.month
        const monthEnd = currentPeriod.end.month

        if (MONTHS.indexOf(monthStart as TMonthName) > MONTHS.indexOf(monthEnd as TMonthName)) {
          if (range === 'start') currentPeriod.end.month = currentPeriod.start.month
          else currentPeriod.start.month = currentPeriod.end.month
        }
      }
      if (reportType === 'QUARTERLY') {
        const quarterStart = currentPeriod.start.quarter
        const quarterEnd = currentPeriod.end.quarter

        if (QUARTERS.indexOf(quarterStart as TQuarterName) > QUARTERS.indexOf(quarterEnd as TQuarterName)) {
          if (range === 'start') currentPeriod.end.quarter = currentPeriod.start.quarter
          else currentPeriod.start.quarter = currentPeriod.end.quarter
        }
      }
    }

    editPeriodValue(currentPeriod)
  }

  function editPeriodValue(newPeriodValue: IBdrPeriod) {
    const localPeriods = [...values.periods]
    const localPeriodIndex = localPeriods.findIndex((period) => period.id === periodId)
    localPeriods[localPeriodIndex] = newPeriodValue

    setFieldValue('periods', localPeriods)
  }

  return history.location.search ? (
    <>
      <Stack direction={'row'}>
        {currentReportType == 'MONTHLY' && (
          <ControlPanel.CalendarRange
            disabled={disabled}
            isTitleDisabled={true}
            textFieldProps={{
              SelectProps: {
                MenuProps: {
                  sx: {
                    maxHeight: '480px !important',
                  },
                },
              },
            }}
            rangeFromParams={{
              value: currentPeriodById.start.month,
              onChange: (e) => changeMonthRange('start', e.target.value),
              options: getMonthOptions('from'),

              children: (
                <FormControl>
                  {!currentPeriodById.start.year && <InputLabel style={{ top: '-11px' }}>гггг</InputLabel>}
                  <ControlPanel.Select
                    disabled={disabled}
                    value={currentPeriodById.start.year}
                    onSelectChange={(value) => changeYearRange('start', value)}
                    style={{ width: currentPeriodById.start.year ? 'auto' : '72px' }}
                  >
                    {getYearOptions()}
                  </ControlPanel.Select>
                </FormControl>
              ),
              placeholder: 'мес',
            }}
            rangeToParams={{
              value: currentPeriodById.end.month,
              onChange: (e) => changeMonthRange('end', e.target.value),
              options: getMonthOptions('to'),
              placeholder: 'мес',
            }}
          />
        )}
        {/* NEW */}
        {currentReportType == 'QUARTERLY' && (
          <ControlPanel.CalendarRange
            disabled={disabled}
            isTitleDisabled={true}
            rangeFromParams={{
              value: currentPeriodById.start.quarter as string,
              onChange: (e) => changeQuarterRange('start', e.target.value as TQuarterName),
              options: getQuarterOptions('from'),
              children: (
                <FormControl>
                  {!currentPeriodById.start.year && <InputLabel style={{ top: '-11px' }}>гггг</InputLabel>}
                  <ControlPanel.Select
                    disabled={disabled}
                    value={currentPeriodById.start.year}
                    onSelectChange={(value) => changeYearRange('start', value)}
                    style={{ width: currentPeriodById.start.year ? 'auto' : '72px' }}
                  >
                    {getYearOptions()}
                  </ControlPanel.Select>
                </FormControl>
              ),
              placeholder: 'кв',
            }}
            rangeToParams={{
              value: currentPeriodById.end.quarter as string,
              onChange: (e) => changeQuarterRange('end', e.target.value as TQuarterName),
              options: getQuarterOptions('to'),
              placeholder: 'кв',
            }}
          />
        )}
        {currentReportType == 'YEARLY' && (
          <ControlPanel.CalendarRange
            disabled={disabled}
            isTitleDisabled={true}
            rangeFromParams={{
              value: currentPeriodById.start.year,
              onChange: (e) => changeYearRange('start', e.target.value),
              options: getYearOptions(),
              placeholder: 'гггг',
            }}
            rangeToParams={{
              value: currentPeriodById.end.year,
              onChange: (e) => changeYearRange('end', e.target.value),
              options: getYearOptions(),
              placeholder: 'гггг',
            }}
          />
        )}
        {currentReportType !== 'YEARLY' && availableYears && (
          <FormControl>
            {!currentPeriodById.end.year && <InputLabel style={{ top: '-11px' }}>гггг</InputLabel>}
            <ControlPanel.Select
              disabled={disabled}
              value={currentPeriodById.end.year}
              onSelectChange={(value) => changeYearRange('end', value)}
              style={{ width: currentPeriodById.end.year ? 'auto' : '72px' }}
            >
              {getYearOptions()}
            </ControlPanel.Select>
          </FormControl>
        )}
      </Stack>
    </>
  ) : (
    <></>
  )
}
