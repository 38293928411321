import React from 'react'
import { Download } from '@mui/icons-material'
import { Box, Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { scrollableWrapper } from '../../global/styles/presets'
import { IDownloadsItem } from '../../global/types/commos-def'
import { theme } from '../../global/styles/theme'
import { ProjectsAPI } from '../../global/api/APIMethods/ProjectsAPI'
import { StyledTypography } from './styles'
import { Delete } from '@mui/icons-material'

export interface IDownloadHistoryProps {
  downloadsList: IDownloadsItem[]
  onDeleteFact: (id: number) => void
}

export const DownloadHistory: React.FC<IDownloadHistoryProps> = ({ downloadsList, onDeleteFact }) => {
  const downloadFile = (id: number, downloadTo: string) => {
    ProjectsAPI.downloadFile(id, downloadTo)
  }

  return (
    <>
      <Box mr={1.25}>
        <TableContainer sx={{ ...scrollableWrapper, height: 'calc(100vh - 140px)' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ textAlign: 'left', paddingLeft: '16px' }}>Дата загрузки</TableCell>
                <TableCell>Количество записей</TableCell>
                <TableCell>Доходы</TableCell>
                <TableCell>Расходы</TableCell>
                <TableCell>Период</TableCell>
                <TableCell>Загрузил</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {downloadsList.map((downloadItem) => {
                const {
                  id,
                  downloadTo,
                  totalRecords,
                  income,
                  outcome,
                  period,
                  downloadBy: { firstName, lastName, middleName },
                } = downloadItem
                return (
                  <TableRow key={downloadItem.id}>
                    <TableCell align="left" sx={{ textAlign: 'left', paddingLeft: '16px' }}>
                      {downloadTo}
                    </TableCell>
                    <TableCell align="center">{totalRecords}</TableCell>
                    <TableCell align="center">
                      <StyledTypography variant="body2">
                        {income.toLocaleString('ru-RU', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </StyledTypography>
                    </TableCell>
                    <TableCell align="center">
                      <StyledTypography variant="body2">
                        {outcome.toLocaleString('ru-RU', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </StyledTypography>
                    </TableCell>
                    <TableCell align="center">{period}</TableCell>
                    <TableCell align="center">
                      {lastName
                        ? lastName +
                          ' ' +
                          (firstName ? firstName[0] + '.' : '') +
                          (middleName ? middleName[0] + '.' : '')
                        : 'Нет ФИО'}
                    </TableCell>
                    <TableCell align="center" style={{ width: '60px' }}>
                      <Stack direction="row">
                        <Button size="small" variant="text" onClick={() => downloadFile(id, downloadTo)}>
                          <Download fontSize="small" sx={{ color: `${theme.palette.primary.main}` }} />
                        </Button>
                        <Button color="error" size="small" sx={{ marginX: 'auto' }} onClick={() => onDeleteFact(id)}>
                          <Delete color={'error'} fontSize="small" />
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
