import { AxiosResponse } from 'axios'
import { Buffer } from 'buffer'
import { IFinCenterOperation, IFinanceCenter, TBudgetType } from '../../types/commos-def'
import { IQueryObj, errorHandler, req, successHandler } from '../api'
import {
  BDDSSubBillRecord,
  IBDDSBudgetSubBillsListQuery,
  IBudgetRelationhsipsResponse,
  IBudgetReportResponse,
  IFinCenterBudgetCheckYearFillingQuery,
  IFinCenterBudgetQuery,
  IFinanceCenterTotalReportResponse,
  IReportQuery,
  ISubBillBDDSRecordRequestBody,
} from '../definitions'

export class BudgetAPI {
  static baseURL: string = '/budget'

  static async getOperations(
    query: IFinCenterBudgetQuery | IQueryObj,
    budgetType: 'bdr' | 'bdds',
    controller: AbortController,
  ): Promise<IBudgetReportResponse> {
    return await req
      .get('/' + budgetType + this.baseURL + '/report/get', query, undefined, controller)
      .then(({ data }) => {
        return data
      })
      .catch((error) => {
        error.name !== 'CanceledError' && errorHandler(error)
      })
  }

  static async getOperationsWithRelationships(
    query: IFinCenterBudgetQuery | IQueryObj,
    budgetType: TBudgetType,
  ): Promise<IBudgetRelationhsipsResponse> {
    return await req
      .get('/' + budgetType + this.baseURL + '/report/get/with-relationships', query)
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async changeBudgetFillingMode(financeCenterId: number, body: any) {
    return await req.put(`/finance-center/${Number(financeCenterId)}/filling-mode`, body)
  }

  static async createOrUpdateOperation(
    body: Partial<IFinCenterOperation>,
    queryParams: Partial<IFinCenterBudgetQuery> | IQueryObj,
    budgetType: 'bdr' | 'bdds',
  ) {
    return await req
      .post(
        '/' + budgetType + this.baseURL + '/report/record/update',
        body,
        queryParams as unknown as { [key: string]: string | number },
      )
      .then(({ data }) => {
        !body.id ? successHandler('Операция создана') : successHandler('Операция обновлена')
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async deleteOperation(body: IFinCenterOperation['id'][], budgetType: 'bdr' | 'bdds') {
    return await req
      .post('/' + budgetType + this.baseURL + '/report/record/delete/list', { operations: body })
      .then(({ data }) => {
        successHandler('Операция удалена')
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async getTotal(
    query: Partial<IReportQuery>,
    budgetType: TBudgetType,
    controller: AbortController,
  ): Promise<IFinanceCenterTotalReportResponse> {
    return await req
      .get('/' + budgetType + this.baseURL + '/report/get/total', query, undefined, controller)
      .then(({ data }) => {
        return data
      })
      .catch((error) => {
        error.name !== 'CanceledError' && errorHandler(error)
      })
  }

  static async getExcelReport(query: Partial<IFinCenterBudgetQuery>, financeCenter: IFinanceCenter): Promise<void> {
    return await req
      .get('/' + query.budgetType + this.baseURL + '/report/get/excel', query)
      .then(({ data }) => {
        const blobUrl = window.URL.createObjectURL(new Blob([Buffer.from(data, 'base64')]))
        const link = document.createElement('a')
        link.href = blobUrl
        link.setAttribute(
          'download',
          `Бюджет ${financeCenter.type === 'DEPARTMENT' ? 'отдела' : 'подразделения'} ${financeCenter.name.replace(
            /[\"]/gm,
            '',
          )}.xlsx`,
        )
        link.click()
        window.URL.revokeObjectURL(blobUrl)
        return
      })
      .catch((error) => errorHandler(error))
  }

  static async checkYearFilling(
    query: Partial<IFinCenterBudgetCheckYearFillingQuery>,
    budgetType: TBudgetType = 'bdr',
  ): Promise<boolean> {
    return await req
      .get('/' + budgetType + this.baseURL + '/report/get/checkYearFilling', query)
      .then(({ data }) => data)
      .catch((error) => errorHandler(error))
  }

  /**
   * добавил категорию
   * обновил эндпоинт
   * добавил переменную которая отвечает за нейминг файла
   * @param category
   * @param financeCenterID
   * @param projectID
   */
  static async downloadTeplateXLS(category: string, financeCenterID: string, projectID: string): Promise<void> {
    return await req
      .get(
        `/bdr${this.baseURL}/report/import/template?financeCenterID=${financeCenterID}&projectID=${projectID}&category=${category}`,
      )
      .then(({ data }) => {
        const fileName = {
          INCOME: 'Доходы',
          WAGE_FUND: 'ФОТ',
          STAFF_MAINTENANCE: 'Содержание персонала',
          OPERATING_EXPENSES: 'Расходы',
        }
        const blobUrl = window.URL.createObjectURL(new Blob([Buffer.from(data, 'base64')]))
        const link = document.createElement('a')
        link.href = blobUrl
        link.setAttribute('download', `${fileName[category as keyof typeof fileName] ?? 'ФОТ'}.xlsx`)
        link.click()
        window.URL.revokeObjectURL(blobUrl)
        return
      })
      .catch((error) => errorHandler(error))
  }

  static async startUploadTask(
    financeCenterID: string,
    projectID: string,
    formData: FormData,
  ): Promise<AxiosResponse<any>> {
    return await req.post(
      `/bdr${this.baseURL}/report/record/start-upload-task?financeCenterID=${financeCenterID}&projectID=${projectID}`,
      formData,
    )
    // .then(({ data }) => {
    //   return;
    // })
    // .catch((error) => errorHandler(error));
  }
}

export class BddsSubBills {
  static baseURL: string = '/bdds/budget/sub-bills-records'

  static async getBddsSubBillsRecordsList(params: IBDDSBudgetSubBillsListQuery): Promise<BDDSSubBillRecord[]> {
    // bdds/budget/sub-bills-records/list?financeCenterID=1
    return await req
      .get(`${this.baseURL}/list`, params)
      .then((data) => data.data)
      .catch((error) => errorHandler(error))
  }

  static async updateBddsSubBillsRecord(
    financeCenterID: number,
    recordID: number,
    body: ISubBillBDDSRecordRequestBody,
  ): Promise<void> {
    return await req
      .post(`${this.baseURL}/update?financeCenterID=${financeCenterID}&recordID=${recordID}`, body)
      .then(() => {
        successHandler('Запись субсчёта обновлена')
      })
      .catch((error) => errorHandler(error))
  }
}
