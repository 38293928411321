import { Box, TableCell, TableHead, TableProps, TableRow, TableRowProps } from '@mui/material'
import qs from 'qs'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { CalendarHeadings } from '../../../components/CalendarHeadings/CalendarHeadings'
import { EENumber } from '../../../components/editableElements/EENumber'
import {
  EETableRowContext,
  EETableRowProvider,
  IValidationErrors,
} from '../../../components/editableElements/EETableRow'
import { ProductionProgramAPI } from '../../../global/api/APIMethods/ProductionProgramAPI'
import { getDeepCopy, getQueryParam } from '../../../global/functions'
import { usePrevState } from '../../../global/hooks'
import {
  tableCellInputSmall_ViewMode,
  tableCellInputSmall,
  operationNominal,
  operationNominal_ViewMode,
} from '../../../global/styles/presets'
import { theme } from '../../../global/styles/theme'
import {
  IPrPrPlanningAndAnaliticsOperation,
  IPrPrOperationUnit,
  IEstimatePlanningItem,
  TEEMode,
} from '../../../global/types/commos-def'
import { MONTHS } from '../../../global/variables'
import { EstimateTable } from './EstimateTable'
import { IProductionProgramState } from '../ProductionProgram-def'

interface IPrPrPlanningRowProps {
  operation: IPrPrPlanningAndAnaliticsOperation
  prPrState: IProductionProgramState
  setPrPrState: React.Dispatch<React.SetStateAction<IProductionProgramState>>
}

interface IStatisticsProps {
  variant: 'operation' | 'total'
  statItem: IPrPrOperationUnit | IEstimatePlanningItem
}

interface IOperationUnitProps {
  mode: TEEMode
  unit: IPrPrOperationUnit | IEstimatePlanningItem
  unitIndex: number
  setRowState: React.Dispatch<React.SetStateAction<IPrPrPlanningRowState>>
  rowState: IPrPrPlanningRowState
}

interface IRowTotalUnitProps {
  unit: IPrPrOperationUnit | IEstimatePlanningItem
}

interface IPrPrPlanningRowState {
  changed: boolean
  operation: IPrPrPlanningAndAnaliticsOperation
  delta: Partial<IPrPrPlanningAndAnaliticsOperation>
}

export const PlanningTable = {
  Wrapper: (props: TableProps) => (
    <EstimateTable.Wrapper {...props} sx={{ ...props.sx, height: 'calc(100% - 185px)' }}>
      {props.children}
    </EstimateTable.Wrapper>
  ),
  Headings: function Headings(props: TableRowProps) {
    return (
      <TableHead>
        <TableRow>
          <TableCell sx={{ textAlign: 'left', pl: 2 }}>Наименование работ</TableCell>
          <TableCell sx={{ width: '100px' }}>Показатели</TableCell>
          <TableCell sx={{ width: '140px' }}>К реализации</TableCell>
          <CalendarHeadings />
        </TableRow>
      </TableHead>
    )
  },
  Row: function Row(props: IPrPrPlanningRowProps) {
    const { operation, prPrState, setPrPrState } = props
    const [state, setState] = React.useState({
      changed: false,
      operation: operation,
      delta: {
        leftToRealizedTotal: {
          genContractValue: 0,
          income: 0,
          percent: 0,
          rentability: 0,
          subContractValue: 0,
        },
        units: [
          ...MONTHS.map((month) => {
            return {
              genContractValue: 0,
              subContractValue: 0,
              income: 0,
              rentability: 0,
              percent: 0,
              sum: null,
              type: 'MONTH',
              scale: {
                month: month,
              },
            }
          }),
        ],
      },
    } as unknown as IPrPrPlanningRowState)

    const history = useHistory()

    let prevDelta = usePrevState(state.delta) as unknown as Partial<IPrPrPlanningAndAnaliticsOperation>

    let timeout: NodeJS.Timeout

    const EETableRowCTX = useContext(EETableRowContext)

    React.useEffect(() => {
      clearTimeout(timeout)
      if (state.changed) {
        timeout = setTimeout(() => setNewDeltaState(operation, state.operation), 300)
      }
      return () => {
        clearTimeout(timeout)
      }
    }, [state.operation])

    React.useEffect(() => {
      if (state.changed) {
        getAndApplyIntermediateDeltaToTotalRow(prevDelta)
      }
    }, [state.delta])

    // Цикл калькуляций построен на основе применения разниц между значениями до и после изменения их пользователем.

    // 1. Меняем данные в строке, а именно
    function changeUnitPercentAndRecalculateRelatedRowValues(
      unitIndex: number,
      key: keyof IPrPrOperationUnit,
      newValue: number | string | undefined,
    ) {
      let operationCopy = getDeepCopy(state.operation) as IPrPrPlanningAndAnaliticsOperation
      // 1.1. Перезаписываем новый процент
      operationCopy.units[unitIndex][key] = newValue as never

      // 1.2. Получаем сумму процентов по строке
      let newCurrentYearTotalPercent = operationCopy.units
        .filter((unit) => unit.type != 'TOTAL')
        .map((unit) => unit.percent)
        .reduce((p: number, n: number) => p + n, 0)

      // 1.3. Записываем получившуюся сумму по строке в TOTAL unit
      operationCopy.units[12].percent = newCurrentYearTotalPercent

      // 1.4. На основе полученного значения ИТОГО по строке вычисляем процент плана, оставшегося к реализации
      operationCopy.leftToRealizedTotal.percent =
        operation.leftToRealizedTotal.percent + (operation.units[12].percent - operationCopy.units[12].percent)

      // 1.5. Перемножаем полученные проценты на соответствющие значения сметы, чтобы обновить содержимое unit-о подобных объектов
      operationCopy = multiplyUnitValuesByPercent(operationCopy)

      operationCopy = multiplyLeftToRealizedValuesByPercent(operationCopy)

      // 1.6. Записываем измененную операцию в стейт. Помечаем, что она изменена, для того, чтобы отработал useEffect, который в последующем посчитает разницу (дельту)
      setState((prevState) => ({
        ...prevState,
        changed: true,
        operation: operationCopy,
      }))
    }

    // 2. Получаем и записываем разницу значений операции между значениями, что прислал сервер, и значениями текущего стейта, изменяемого пользователем.
    // Эта функция всегда считает и перезаписывает исключительно вышеописанную разницу, чтобы всегда была доступна стабильная версия дельты между сервером и текущим стейтом.
    function setNewDeltaState(
      serverOperationAsProp: IPrPrPlanningAndAnaliticsOperation,
      operationState: IPrPrPlanningAndAnaliticsOperation,
    ) {
      let serverOperation = getDeepCopy(serverOperationAsProp) as IPrPrPlanningAndAnaliticsOperation
      let changedOperation = getDeepCopy(operationState) as IPrPrPlanningAndAnaliticsOperation

      let targetDelta = {
        leftToRealizedTotal: {} as IEstimatePlanningItem,
        units: [] as IPrPrOperationUnit[],
      }

      targetDelta.units = [...calculateUnitDelta(serverOperation, changedOperation)]

      targetDelta.leftToRealizedTotal = { ...calculateLeftToRealizedDelta(serverOperation, changedOperation) }

      setState((prevState) => ({
        ...prevState,
        delta: targetDelta,
      }))
    }

    // 3. Для того, чтобы в строке ВСЕГО всегда были корректные данные, необходимо применять "разницу разниц", а именно разницу между состоянием дельты до и после изменения state.delta.
    // Отличие от п.2 в том, что мы не храним эту дельту, и получаем ее на основании предыдущего и текущего значений дельты. Это значение динамическое, и ниже по коду
    // применяется в названии с пометкой "intermediateDelta". Такое разделение было необходимо, чтобы калькуляции внутри строки происходили мгновенно, а применение дельты к строке ВСЕГО
    // происходило через определенный таймер (0,3с) после того, как пользователь прекратил редактировать строку.
    function getAndApplyIntermediateDeltaToTotalRow(prevDelta: Partial<IPrPrPlanningAndAnaliticsOperation>) {
      if (prPrState?.recalculatedTotal) {
        let totalCopy = getDeepCopy(prPrState?.recalculatedTotal) as Partial<IPrPrPlanningAndAnaliticsOperation>
        let intermediateDelta = getDeepCopy(state.delta) as IPrPrPlanningRowState['delta']

        intermediateDelta.units = getUnitsIntermediateDelta(prevDelta, intermediateDelta)
        intermediateDelta.leftToRealizedTotal = getLeftToRealizedIntermediateDelta(prevDelta, intermediateDelta)

        totalCopy.units = getUnitsWithAppliedIntermediateDelta(totalCopy, intermediateDelta)
        totalCopy.leftToRealizedTotal = {
          ...getLeftToRealizedTotalWithAppliedIntermediateDelta(totalCopy, intermediateDelta),
        }

        setPrPrState((prevState) => ({
          ...prevState,
          recalculatedTotal: totalCopy,
        }))
      }
    }

    // Сопутствующие функции

    // 1. Перемножаем имеющейся процент на сщщтветствующие показатели сметы, чтобы получить обновленный unit/leftToRealizedTotal

    function multiplyUnitValuesByPercent(operation: IPrPrPlanningAndAnaliticsOperation) {
      let operationCopy = getDeepCopy(operation) as IPrPrPlanningAndAnaliticsOperation
      operationCopy.units = [
        ...operationCopy.units.map((unit) => {
          for (let key in unit) {
            switch (key) {
              case 'genContractValue':
                unit[key] = ((operationCopy.estimate.genContractTotal || 0) * unit.percent) / 100
                break
              case 'subContractValue':
                unit[key] = ((operationCopy.estimate.subContractTotal || 0) * unit.percent) / 100
                break
            }
          }
          return unit
        }),
      ]
      return operationCopy
    }

    function multiplyLeftToRealizedValuesByPercent(operation: IPrPrPlanningAndAnaliticsOperation) {
      let operationCopy = getDeepCopy(operation) as IPrPrPlanningAndAnaliticsOperation
      for (let key in operationCopy.leftToRealizedTotal) {
        switch (key) {
          case 'genContractValue':
            operationCopy.leftToRealizedTotal[key] =
              ((operationCopy.estimate.genContractTotal || 0) * operationCopy.leftToRealizedTotal.percent) / 100
            break
          case 'subContractValue':
            operationCopy.leftToRealizedTotal[key] =
              ((operationCopy.estimate.subContractTotal || 0) * operationCopy.leftToRealizedTotal.percent) / 100
            break
        }
      }
      return operationCopy
    }

    // 2. Калькулируем дельты как разницу между значениями операции, что пришли как props от сервера (статичные) и измененной пользователем операции

    function calculateUnitDelta(
      serverOperationAsProp: IPrPrPlanningAndAnaliticsOperation,
      operationState: IPrPrPlanningAndAnaliticsOperation,
    ) {
      let serverOperation = getDeepCopy(serverOperationAsProp) as IPrPrPlanningAndAnaliticsOperation
      let changedOperation = getDeepCopy(operationState) as IPrPrPlanningAndAnaliticsOperation

      return (
        serverOperation.units.map((unit, index: number) => {
          let delta = {
            ...unit,
            percent: changedOperation.units[index].percent - unit.percent,
            genContractValue: changedOperation.units[index].genContractValue - unit.genContractValue,
            subContractValue: changedOperation.units[index].subContractValue - unit.subContractValue,
          }
          return delta
        }) || []
      )
    }

    function calculateLeftToRealizedDelta(
      serverOperationAsProp: IPrPrPlanningAndAnaliticsOperation,
      operationState: IPrPrPlanningAndAnaliticsOperation,
    ) {
      let serverOperation = getDeepCopy(serverOperationAsProp) as IPrPrPlanningAndAnaliticsOperation
      let changedOperation = getDeepCopy(operationState) as IPrPrPlanningAndAnaliticsOperation
      let leftToRealized = {} as IEstimatePlanningItem

      for (let key in changedOperation?.leftToRealizedTotal) {
        leftToRealized[key as keyof IEstimatePlanningItem] =
          changedOperation.leftToRealizedTotal[key as keyof IEstimatePlanningItem] -
          serverOperation.leftToRealizedTotal[key as keyof IEstimatePlanningItem]
      }

      return leftToRealized
    }

    // 3. Сравниваем стейт дельты до и после новых изменений, и получаем разницу состояний. Тот самый "intermediateDelta", который в последующем применим к строке ВСЕГО

    function getUnitsIntermediateDelta(
      prevDelta: Partial<IPrPrPlanningAndAnaliticsOperation>,
      newDelta: Partial<IPrPrPlanningAndAnaliticsOperation>,
    ) {
      return newDelta?.units?.map((unit, index) => {
        return {
          ...unit,
          percent: unit.percent - (prevDelta?.units?.[index]?.percent || 0),
          genContractValue: unit.genContractValue - (prevDelta?.units?.[index]?.genContractValue || 0),
          subContractValue: unit.subContractValue - (prevDelta?.units?.[index]?.subContractValue || 0),
        }
      })
    }

    function getLeftToRealizedIntermediateDelta(
      prevDelta: Partial<IPrPrPlanningAndAnaliticsOperation>,
      newDelta: Partial<IPrPrPlanningAndAnaliticsOperation>,
    ) {
      let leftToRealized = {} as IEstimatePlanningItem

      for (let key in newDelta?.leftToRealizedTotal) {
        leftToRealized[key as keyof IEstimatePlanningItem] =
          newDelta?.leftToRealizedTotal[key as keyof IEstimatePlanningItem] -
          (prevDelta?.leftToRealizedTotal?.[key as keyof IEstimatePlanningItem] || 0)
      }
      return leftToRealized
    }

    // 4. Применяем полученную "intermediateDelta" к значениям строки ВСЕГО

    function getUnitsWithAppliedIntermediateDelta(
      total: Partial<IPrPrPlanningAndAnaliticsOperation>,
      intermediateDelta: Partial<IPrPrPlanningAndAnaliticsOperation>,
    ) {
      let totalUnit = {
        percent: 0,
        genContractValue: 0,
        subContractValue: 0,
      } as IPrPrOperationUnit
      return total?.units?.map((unit, index) => {
        if (total.units) {
          if (unit.type != 'TOTAL') {
            let genContractValue = unit.genContractValue + (intermediateDelta?.units?.[index]?.genContractValue || 0)
            let subContractValue = unit.subContractValue + (intermediateDelta?.units?.[index]?.subContractValue || 0)
            let percent = total.estimate?.genContractTotal
              ? (genContractValue / total.estimate.genContractTotal) * 100
              : 0

            totalUnit.genContractValue += Number(genContractValue.toFixed(2))
            totalUnit.subContractValue += subContractValue

            return {
              ...unit,
              genContractValue: genContractValue,
              subContractValue: subContractValue,
              percent: percent,
            }
          } else {
            totalUnit.percent += total.estimate?.genContractTotal
              ? (totalUnit.genContractValue / total.estimate?.genContractTotal) * 100
              : 0
            return {
              ...unit,
              ...totalUnit,
            }
          }
        } else {
          return unit
        }
      })
    }

    function getLeftToRealizedTotalWithAppliedIntermediateDelta(
      total: Partial<IPrPrPlanningAndAnaliticsOperation>,
      intermediateDelta: Partial<IPrPrPlanningAndAnaliticsOperation>,
    ) {
      let genContractValue =
        (total.leftToRealizedTotal?.genContractValue || 0) +
        (intermediateDelta.leftToRealizedTotal?.genContractValue || 0)
      let subContractValue =
        (total.leftToRealizedTotal?.subContractValue || 0) +
        (intermediateDelta.leftToRealizedTotal?.subContractValue || 0)
      let percent = total.estimate?.genContractTotal ? (genContractValue / total.estimate.genContractTotal) * 100 : 0

      return {
        genContractValue: genContractValue,
        subContractValue: subContractValue,
        percent: percent,
      } as IEstimatePlanningItem
    }

    // ======================================================================================================

    function updateOperation() {
      ProductionProgramAPI.updatePlanningOperation(state.operation, {
        financeCenterID: Number(getQueryParam(history, 'financeCenterID')),
        projectID: Number(getQueryParam(history, 'projectID')),
        year: Number(getQueryParam(history, 'yearStart')),
        analysisType: 'PLAN',
      })
    }

    function isEditDisabled() {
      let queryParams = qs.parse(history.location.search)
      if (
        queryParams.reportType == 'MONTHLY' &&
        queryParams.monthStart == 'JANUARY' &&
        queryParams.monthEnd == 'DECEMBER'
      ) {
        return false
      } else {
        return true
      }
    }

    return (
      <EETableRowProvider
        isEditDisabled={isEditDisabled()}
        key={operation.id}
        onSwitchMode={(prevMode, currentMode) => {
          prevMode == 'edit' && updateOperation()
        }}
        validation={() => validateRow(state.operation)}
      >
        <EETableRowContext.Consumer>
          {(value) => (
            <>
              <TableCell align="left" sx={{ pl: 2 }}>
                {state.operation.name}
              </TableCell>
              <TableCell align="left">
                <Box sx={{ ...tableCellInputSmall_ViewMode, justifyContent: 'flex-end' }}>%</Box>
                <Box sx={{ ...tableCellInputSmall_ViewMode, justifyContent: 'flex-end' }}>Генподряд</Box>
                <Box sx={{ ...tableCellInputSmall_ViewMode, justifyContent: 'flex-end' }}>Субподряд</Box>
              </TableCell>
              <TableCell align="left">
                <Box display={'flex'} flexDirection={'column'}>
                  <EENumber
                    mode={'view'}
                    name={'percent'}
                    value={state.operation.leftToRealizedTotal?.percent}
                    viewModeStyle={{
                      ...tableCellInputSmall_ViewMode,
                      color: !value.validationErrors.leftToRealizedTotal_percent?.isValid
                        ? theme.palette.error.main
                        : theme.palette.text.primary,
                    }}
                    TextFieldProps={{
                      sx: tableCellInputSmall,
                    }}
                    NumberFormatProps={{
                      suffix: ' %',
                    }}
                  />
                  <EENumber
                    mode={'view'}
                    name={'genContractValue'}
                    value={state.operation.leftToRealizedTotal?.genContractValue}
                    viewModeStyle={tableCellInputSmall_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInputSmall,
                    }}
                  />
                  <EENumber
                    mode={'view'}
                    name={'subContractValue'}
                    value={state.operation.leftToRealizedTotal?.subContractValue}
                    viewModeStyle={tableCellInputSmall_ViewMode}
                    TextFieldProps={{
                      sx: tableCellInputSmall,
                    }}
                  />
                </Box>
              </TableCell>
              {state.operation?.units?.map((unit, index) => {
                if (unit.type != 'TOTAL') {
                  return (
                    <TableCell align="left" sx={{ minWidth: '80px' }}>
                      <Box display={'flex'} flexDirection={'column'}>
                        <EENumber
                          mode={value.mode}
                          name={'percent'}
                          value={unit.percent}
                          viewModeStyle={operationNominal_ViewMode}
                          TextFieldProps={{
                            sx: operationNominal,
                          }}
                          NumberFormatProps={{
                            suffix: ' %',
                          }}
                          onNumberChange={(values) =>
                            changeUnitPercentAndRecalculateRelatedRowValues(index, 'percent', values.floatValue)
                          }
                        />
                        <EENumber
                          mode={'view'}
                          name={'genContractValue'}
                          value={unit.genContractValue}
                          viewModeStyle={tableCellInputSmall_ViewMode}
                          TextFieldProps={{
                            sx: tableCellInputSmall,
                          }}
                        />
                        <EENumber
                          mode={'view'}
                          name={'subContractValue'}
                          value={unit.subContractValue}
                          viewModeStyle={tableCellInputSmall_ViewMode}
                          TextFieldProps={{
                            sx: tableCellInputSmall,
                          }}
                        />
                      </Box>
                    </TableCell>
                  )
                } else {
                  return (
                    <TableCell align="left" sx={{ minWidth: '80px' }}>
                      <Box display={'flex'} flexDirection={'column'}>
                        <EENumber
                          mode={'view'}
                          name={'rowTotalPercent'}
                          value={unit.percent}
                          viewModeStyle={{
                            ...operationNominal_ViewMode,
                            color: !value.validationErrors.leftToRealizedTotal_percent?.isValid
                              ? theme.palette.error.main
                              : theme.palette.text.primary,
                          }}
                          TextFieldProps={{
                            sx: operationNominal,
                          }}
                          NumberFormatProps={{
                            suffix: ' %',
                          }}
                        />
                        <EENumber
                          mode={'view'}
                          name={'genContractValue'}
                          value={unit.genContractValue}
                          viewModeStyle={tableCellInputSmall_ViewMode}
                          TextFieldProps={{
                            sx: tableCellInputSmall,
                          }}
                        />
                        <EENumber
                          mode={'view'}
                          name={'subContractValue'}
                          value={unit.subContractValue}
                          viewModeStyle={tableCellInputSmall_ViewMode}
                          TextFieldProps={{
                            sx: tableCellInputSmall,
                          }}
                        />
                      </Box>
                    </TableCell>
                  )
                }
              })}
            </>
          )}
        </EETableRowContext.Consumer>
      </EETableRowProvider>
    )
  },
  TableTotalRow: function TableTotalRow(props: { operation: IPrPrPlanningAndAnaliticsOperation }) {
    const { operation } = props

    return (
      <TableRow>
        <TableCell align="left" sx={{ pl: 2 }}>
          ВСЕГО
        </TableCell>
        <TableCell align="left">
          <Box sx={{ ...tableCellInputSmall_ViewMode, justifyContent: 'flex-end' }}>%</Box>
          <Box sx={{ ...tableCellInputSmall_ViewMode, justifyContent: 'flex-end' }}>Генподряд</Box>
          <Box sx={{ ...tableCellInputSmall_ViewMode, justifyContent: 'flex-end' }}>Субподряд</Box>
        </TableCell>
        <TableCell align="left">
          <Box display="flex" flexDirection="column">
            <EENumber
              mode={'view'}
              name={'percent'}
              value={operation.leftToRealizedTotal?.percent}
              viewModeStyle={{
                ...tableCellInputSmall_ViewMode,
              }}
              TextFieldProps={{
                sx: tableCellInputSmall,
              }}
              NumberFormatProps={{
                suffix: ' %',
              }}
            />
            <EENumber
              mode={'view'}
              name={'genContractValue'}
              value={operation.leftToRealizedTotal?.genContractValue}
              viewModeStyle={tableCellInputSmall_ViewMode}
              TextFieldProps={{
                sx: tableCellInputSmall,
              }}
            />
            <EENumber
              mode={'view'}
              name={'subContractValue'}
              value={operation.leftToRealizedTotal?.subContractValue}
              viewModeStyle={tableCellInputSmall_ViewMode}
              TextFieldProps={{
                sx: tableCellInputSmall,
              }}
            />
          </Box>
        </TableCell>
        {operation.units?.map((unit, index) => {
          return (
            <TableCell align={'left'}>
              <Box display="flex" flexDirection="column">
                <EENumber
                  mode={'view'}
                  name={'rowTotalPercent'}
                  value={unit.percent}
                  viewModeStyle={{
                    ...tableCellInputSmall_ViewMode,
                  }}
                  TextFieldProps={{
                    sx: tableCellInputSmall,
                  }}
                  NumberFormatProps={{
                    suffix: ' %',
                  }}
                />
                <EENumber
                  mode={'view'}
                  name={'genContractValue'}
                  value={unit.genContractValue}
                  viewModeStyle={tableCellInputSmall_ViewMode}
                  TextFieldProps={{
                    sx: tableCellInputSmall,
                  }}
                />
                <EENumber
                  mode={'view'}
                  name={'subContractValue'}
                  value={unit.subContractValue}
                  viewModeStyle={tableCellInputSmall_ViewMode}
                  TextFieldProps={{
                    sx: tableCellInputSmall,
                  }}
                />
              </Box>
            </TableCell>
          )
        })}
      </TableRow>
    )
  },
}

function validateRow(operation: IPrPrPlanningAndAnaliticsOperation): IValidationErrors {
  let validationErrors = {} as IValidationErrors
  if (operation.leftToRealizedTotal?.percent < 0) {
    validationErrors.leftToRealizedTotal_percent = {
      isValid: false,
      message: 'Остаток объема работ к реализации не может быть менее 0%',
    }
  } else {
    validationErrors.leftToRealizedTotal_percent = {
      isValid: true,
      message: '',
    }
  }
  if (operation.units && operation.units[12]?.percent > 100) {
    validationErrors.totalUnit_percent = {
      isValid: false,
      message: 'Объем планируемых работ на год (столбец ИТОГО) не может превышать 100%',
    }
  } else {
    validationErrors.totalUnit_percent = {
      isValid: true,
      message: '',
    }
  }
  return validationErrors
}
