import { FC, useState, MouseEvent } from 'react'
import { IMenuCellProps } from './MenuCell.types'
import { IconButton, Stack } from '@mui/material'
import { IMenuData } from 'components/Menu/Menu.types'
import { Menu } from 'components/Menu'
import { AddTableRowDownIcon, AddTableRowUpIcon } from 'assets/AddTableRowIcon'
import { theme } from 'global/styles/theme'
import { MoreVert as MenuIcon, DeleteOutlineOutlined as DeleteIcon } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import { OperationForCreateOrUpdate } from 'global/api/constructors'
import { IFinCenterBudgetQuery } from 'global/api/definitions'
import { BudgetAPI } from 'global/api/APIMethods/BudgetAPI'
import { changeQueryParams, getQueryParam } from 'global/functions'
import { TBudgetType } from 'global/types/commos-def'
import useConfirmDialog, { UseExitConfirmProps } from 'hooks/useConfirmDialog'
import { BudgetCellWrapper, IconWrapper } from '../../BudgetAgGrid.styles'
import { FinanceCenterAPI } from 'global/api/APIMethods/FinCenterAPI'

export const MenuCell: FC<IMenuCellProps> = ({
  params,
  onAddRow,
  setIsTableShown,
  getRecalculatedTotal,
  setLocalFillingMode,
}) => {
  const { data, node, api } = params
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const history = useHistory()
  const [rowDataForDelete, setRowDataForDelete] = useState<any | null>(null)
  const financeCenterId = getQueryParam(history, 'financeCenterID') as string
  const budgetType = getQueryParam(history, 'budgetType') as TBudgetType

  const onOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    if (!isConfirmDialogOpened) setAnchorEl(event.currentTarget)
  }

  const onCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleDeleteRow = (confirm: boolean) => {
    if (confirm) {
      BudgetAPI.deleteOperation([rowDataForDelete.id], budgetType).then(() => {
        let query = Object.fromEntries(new URLSearchParams(history.location.search)) as unknown as IFinCenterBudgetQuery
        let emptyOperation = new OperationForCreateOrUpdate(query)

        const recalculatedTotal = getRecalculatedTotal(data!, emptyOperation)
        setTimeout(() => {
          api.setPinnedBottomRowData([recalculatedTotal])
        }, 0)

        api.applyTransaction({ remove: [rowDataForDelete] })
        api.redrawRows()

        // @ts-ignore
        if (!api.getModel().rowsToDisplay.length) {
          FinanceCenterAPI.getBudgetById(Number(financeCenterId)).then((data) => setLocalFillingMode(data.fillingMode))
          setIsTableShown(false)
        }
      })
    }
  }

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState('deleteRow')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    deleteRow: {
      handleConfirm: handleDeleteRow,
      title: 'Вы действительно хотите удалить позицию бюджета?',
      body: 'Выбранная Вами позиция удалится безвозвратно.',
      confirmButtonText: 'Удалить',
      confirmButtonColor: 'error',
    },
  }

  const { ConfirmDialog, openConfirm, isConfirmDialogOpened } = useConfirmDialog(
    dataForConfirmDialog[confirmDialogTrigger],
  )

  const onAddClick = (addIndex: number, direction: 'up' | 'down') => {
    let rowRecordId: number = Number(data?.id)

    if (direction === 'down') {
      const rowIndex = Number(node.rowIndex) + 1
      // @ts-ignore
      rowRecordId = api.getModel().rowsToDisplay[rowIndex]?.data?.id
    }

    onAddRow(addIndex, rowRecordId)
    onCloseMenu()
  }

  const onDeleteRow = () => {
    setConfirmDialogTrigger('deleteRow')
    openConfirm()
    setRowDataForDelete(data)
    onCloseMenu()
  }

  const menuData: IMenuData[] = [
    {
      label: 'Добавить строку выше',
      icon: (
        <IconWrapper>
          <AddTableRowUpIcon />
        </IconWrapper>
      ),
      action: () => onAddClick(Number(node.rowIndex), 'up'),
    },
    {
      label: 'Добавить строку ниже',
      icon: (
        <IconWrapper>
          <AddTableRowDownIcon />
        </IconWrapper>
      ),
      action: () => onAddClick(Number(node.rowIndex) + 1, 'down'),
    },
    {
      label: 'Удалить',
      labelColor: theme.palette.text.red,
      icon: <DeleteIcon color="error" fontSize="small" />,
      action: onDeleteRow,
    },
  ]

  return (
    <BudgetCellWrapper>
      <Stack justifyContent={'center'} alignItems={'center'} position={'relative'}>
        <IconButton onClick={onOpenMenu}>
          <MenuIcon fontSize="medium" color="primary" />
        </IconButton>

        <Menu anchorEl={anchorEl} onClose={onCloseMenu} menuData={menuData} />

        <ConfirmDialog />
      </Stack>
    </BudgetCellWrapper>
  )
}
