import { Box, CircularProgress } from '@mui/material'
import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { BudgetControls } from '../../components/BudgetControls/BudgetControls'
import { ControlPanel } from '../../components/ControlPanel/ControlPanel'
import { HelpPlug } from '../../components/HelpPlug/HelpPlug'
import { Report } from '../../components/Report/Report'
import { SubBillAssignMode } from '../../components/SubBillAssignMode/SubBillAssignMode'
import { DefaultDownloadTemplate } from '../../components/UploadFileDrawer/DefaultDownloadTemplate'
import { UploadFileDrawer } from '../../components/UploadFileDrawer/UploadFileDrawer'
import { YearProlongationForm } from '../../components/YearProlongationForm/YearProlongationForm'
import { InterfaceContext } from '../../contexts/context.interface'
import { BddsSubBills, BudgetAPI } from '../../global/api/APIMethods/BudgetAPI'
import { FinanceCenterAPI } from '../../global/api/APIMethods/FinCenterAPI'
import { OrganizationAPI } from '../../global/api/APIMethods/OrganizationAPI'
import { ReportAPI } from '../../global/api/APIMethods/ReportAPI'
import { OperationForCreateOrUpdate } from '../../global/api/constructors'
import {
  BDDSSubBillRecord,
  IFinCenterBudgetQuery,
  IFinanceCenterTotalReportResponse,
} from '../../global/api/definitions'
import { changeQueryParams, getDeepCopy, getQueryParam } from '../../global/functions'
import {
  IFinCenterOperation,
  IFinanceCenter,
  IOperationUnit,
  IProjectInfo,
  IReportRelationshipItem,
  TBudgetType,
  TESNParamsVariant,
  TMonthName,
  TOperationCategory,
  TOperationalActivityCategory,
  TQuarterName,
  TReportType,
} from '../../global/types/commos-def'
import { Params } from '../../global/types/params-def'
import { getAvailableRelationships } from '../../global/utils/getAvailableRelationships'
import { BudgetButtons } from './components/ControlPanel/BudgetButtons'
import { BudgetTypeSelect } from './components/ControlPanel/BudgetTypeSelect'
import { CategoryTabs } from './components/ControlPanel/CategoryTabs'
import { CreateNextRowCheckbox } from './components/ControlPanel/CreateNextRowCheckbox'
import { Subaccount } from './components/ControlPanel/Subaccount'
import { SubcategoryTabs } from './components/ControlPanel/SubcategoryTabs'
import { CopyBudgetDialog } from './components/CopyBudgetDialog'
import { FinanceCenterRecords } from './components/FinanceCenterRecords/FinanceCenterRecords'
import { FinCenterTableHead } from './components/FinanceCenterRecords/components/FinCenterTableHead'
import { PTTtable } from './components/FinanceCenterRecords/components/PTT/PTTtable'
import { TFormatSelectionValue } from './components/FormatSelectionDialog/FormatSelectionDialog.types'
import { useGetCompanyQuery } from '../../api/profile'

export interface IFinanceCenterState {
  operations: IFinCenterOperation[] | null
  activeSubbillToAssign: IReportRelationshipItem | null
  availableRelationships: IReportRelationshipItem[] | null
  project: IProjectInfo | null
  financeCenter: IFinanceCenter | null
  total: IFinCenterOperation | null
  ESNParams: {
    ensType: TESNParamsVariant
    esnFSSPercent: number
  }
  isSubBillAssignMode: boolean
  isProlongationFormOpen: boolean
  isCopyDialogOpen: boolean
  tableRowCount: number
  refresh: boolean
  totalReport: IFinanceCenterTotalReportResponse | null
  isSubBillAssignUserGuideOpen: boolean
  subBillRecordList: BDDSSubBillRecord[] | null
}

export function BudgetPageNew() {
  const { t } = useTranslation('ENUMS')

  const { data: company } = useGetCompanyQuery()

  const history = useHistory()

  const location = useLocation()

  const interfaceCTX = useContext(InterfaceContext)

  const { projectID, financeCenterID } = useParams() as Params

  const category = getQueryParam(history, 'category') as TOperationalActivityCategory
  const budgetType = getQueryParam(history, 'budgetType')
  const reportType = getQueryParam(history, 'reportType') as TReportType
  const currentYear = getQueryParam(history, 'yearStart') as string

  const [state, setState] = React.useState({
    activeSubbillToAssign: null,
    availableRelationships: null,
    operations: null,
    financeCenter: null,
    project: null,
    total: null,
    isSubBillAssignMode: false,
    isProlongationFormOpen: false,
    isCopyDialogOpen: false,
    tableRowCount: 0,
    refresh: false,
    totalReport: null,
    isSubBillAssignUserGuideOpen: false,
    subBillRecordList: null,
  } as IFinanceCenterState)

  const [isUploadDrawerOpen, setIsUploadDrawerOpen] = useState<boolean>(false)
  const [blocking, setBlocking] = useState<{ another_user: string; status: boolean }>({
    another_user: '',
    status: false,
  })

  const [isOperationsLoading, setIsOperationsLoading] = useState<boolean>(false)
  const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState(true)

  React.useEffect(() => {
    OrganizationAPI.getUserPreferences().then((data) =>
      setState((prevState) => ({
        ...prevState,
        ESNParams: {
          ensType: data.ensType,
          esnFSSPercent: data.esnFSSPercent,
        },
      })),
    )
  }, [])

  React.useEffect(() => {
    state.financeCenter && initiateBreadcrumbs(state.financeCenter)
  }, [state.financeCenter])

  React.useEffect(() => {
    let query = new URLSearchParams(history.location.search)
    let financeCenterID = query.get('financeCenterID') as string

    FinanceCenterAPI.getBudgetById(financeCenterID).then((data) =>
      setState((prevState) => ({
        ...prevState,
        financeCenter: data,
      })),
    )
  }, [state.refresh])

  const searchParamsQuery = React.useMemo(() => {
    const query = new URLSearchParams(history.location.search)
    query.delete('isCreateNextRow')
    query.delete('subcategory')
    return query
  }, [location.search])

  React.useEffect(() => {
    let financeCenterID = searchParamsQuery.get('financeCenterID') as string
    const controller = new AbortController()

    if (getQueryParam(history, 'category')) {
      if (getQueryParam(history, 'budgetType') === 'ptt') {
        console.log('start request')
        BddsSubBills.getBddsSubBillsRecordsList({
          financeCenterID,
          monthStart: searchParamsQuery.get('monthStart') as TMonthName,
          monthEnd: searchParamsQuery.get('monthEnd') as TMonthName,
          yearStart: searchParamsQuery.get('yearStart') as string,
          yearEnd: searchParamsQuery.get('yearEnd') as string,
        }).then((data) => {
          const subBillsListData = data ? data : ([] as BDDSSubBillRecord[])
          setState((prevState) => {
            return {
              ...prevState,
              subBillRecordList: subBillsListData,
            }
          })
        })
        return
      }

      setIsOperationsLoading(true)
      getQueryParam(history, 'category') !== 'TOTAL'
        ? BudgetAPI.getOperations(
            Object.fromEntries(searchParamsQuery),
            getQueryParam(history, 'budgetType') as TBudgetType,
            controller,
          ).then((data) => {
            if (!data) return

            // @ts-ignore
            setState((prevState) => ({
              ...prevState,
              operations: data?.operations,
              project: data?.project,
              total: data?.total,
              financeCenter: {
                ...prevState.financeCenter,
                fillingMode: data.financeCenter.fillingMode,
              },
            }))
            setIsOperationsLoading(false)
          })
        : Promise.all([
            FinanceCenterAPI.getBudgetById(financeCenterID),
            BudgetAPI.getTotal(
              {
                ...Object.fromEntries(searchParamsQuery),
                financeCenters: [financeCenterID],
                projects: [getQueryParam(history, 'projectID') as string],
              },
              getQueryParam(history, 'budgetType') as TBudgetType,
              controller,
            ),
          ]).then((data) => {
            if (!data) return

            setState((prevState) => ({
              ...prevState,
              project: data[0]?.project || null,
              financeCenter: data[0],
              totalReport: data[1],
            }))
          })
    }

    return () => {
      controller.abort()
      setState((prevState) => ({
        ...prevState,
        operations: null,
        totalReport: null,
        total: null,
      }))
    }
  }, [searchParamsQuery.toString(), state.refresh, state.isSubBillAssignMode])

  React.useEffect(() => {
    if (state.isSubBillAssignMode) {
      ReportAPI.getSubBills(
        getQueryParam(history, 'budgetType') as TBudgetType,
        Object.fromEntries(searchParamsQuery),
      ).then((data) =>
        setState((prevState) => ({
          ...prevState,
          availableRelationships: data?.data,
        })),
      )
    }
    return () => {
      setState((prevState) => ({
        ...prevState,
        activeSubbillToAssign: null,
        availableRelationships: null,
      }))
    }
  }, [state.isSubBillAssignMode, getQueryParam(history, 'budgetType')])

  React.useEffect(() => {
    const value = [Boolean(state.totalReport), Boolean(state.totalReport?.data.children.length)].includes(false)
    updateDownloadButtonDisabled(value)
  }, [state.totalReport])

  function recalculateTotal(prevState: IFinCenterOperation, newState: IFinCenterOperation) {
    let copyTotal = state.total && getDeepCopy(state.total)
    copyTotal.units =
      state.total &&
      state.total.units?.map((unit: IOperationUnit, totalUnitIndex: number) => {
        prevState.units.map((prevStateUnit: IOperationUnit, prevStateUnitIndex: number) => {
          newState.units.map((newStateUnit, newStateUnitIndex: number) => {
            if (
              totalUnitIndex === prevStateUnitIndex &&
              prevStateUnitIndex === newStateUnitIndex &&
              newStateUnitIndex === totalUnitIndex &&
              prevStateUnit.sum !== newStateUnit.sum
            ) {
              let difference = newStateUnit.sum - prevStateUnit.sum
              unit.sum += difference
            }
          })
        })
        return unit
      })

    if (getQueryParam(history, 'category') === 'WAGE_FUND') {
      copyTotal.esnTotal.units = state.total?.esnTotal?.units.map((unit: IOperationUnit, totalUnitIndex: number) => {
        prevState.esnTotal?.units.map((prevStateUnit: IOperationUnit, prevStateUnitIndex: number) => {
          newState.esnTotal?.units.map((newStateUnit, newStateUnitIndex: number) => {
            if (
              totalUnitIndex === prevStateUnitIndex &&
              prevStateUnitIndex === newStateUnitIndex &&
              newStateUnitIndex === totalUnitIndex &&
              prevStateUnit.sum !== newStateUnit.sum
            ) {
              let difference = newStateUnit.sum - prevStateUnit.sum
              unit.sum += difference
            }
          })
        })
        return unit
      })
    }
    setState((prevState) => ({
      ...prevState,
      total: copyTotal,
    }))
  }

  function createOperation(type: TFormatSelectionValue) {
    // setOpenedModalWindow(null)

    let query = Object.fromEntries(new URLSearchParams(history.location.search)) as unknown as IFinCenterBudgetQuery
    let newOperation = new OperationForCreateOrUpdate(query)
    BudgetAPI.createOrUpdateOperation(
      newOperation,
      {
        financeCenterID: query.financeCenterID,
        projectID: query.projectID,
        category: query.category,
        year: query.yearStart,
        analysisType: 'PLAN',
      },
      getQueryParam(history, 'budgetType') as TBudgetType,
    ).then((data) => {
      newOperation.id = data
      setState((prevState) => ({
        ...prevState,
        total:
          prevState.operations && prevState.operations.length === 0
            ? new OperationForCreateOrUpdate(query)
            : prevState.total,
        operations: [newOperation, ...(prevState.operations || [])],
      }))
      changeQueryParams(history, {
        reportType: 'MONTHLY',
        monthStart: 'JANUARY',
        monthEnd: 'DECEMBER',
      })
    })
  }

  function deleteOperation(operationID: number) {
    BudgetAPI.deleteOperation([operationID], getQueryParam(history, 'budgetType') as TBudgetType).then(() => {
      setState((prevState) => ({
        ...prevState,
        refresh: !prevState.refresh,
      }))
    })
  }

  function refreshOperation() {
    setState((prevState) => ({
      ...prevState,
      refresh: !prevState.refresh,
    }))
  }

  function switchSubBillAssignMode() {
    setState((prevState) => ({
      ...prevState,
      isSubBillAssignMode: !prevState.isSubBillAssignMode,
    }))
  }

  function changeBlocking(status: boolean, another_user: string) {
    setBlocking({
      another_user: another_user,
      status: status,
    })
  }

  function openProlongationForm(isOpen: boolean) {
    setState((prevState) => {
      return {
        ...prevState,
        isProlongationFormOpen: isOpen,
      }
    })
  }

  const handleCopyDialogToggle = (isOpen: boolean) => () => {
    setState((prevState) => {
      return {
        ...prevState,
        isCopyDialogOpen: isOpen,
      }
    })
  }

  function initiateBreadcrumbs(financeCenter: IFinanceCenter) {
    interfaceCTX.setBreadcrumbsProps(
      [
        {
          variant: 'link',
          title: `Проект ${financeCenter.project!.name}`,
          path: `/projects/${financeCenter.project!.id}/menu`,
        },
        {
          variant: 'link',
          title: `Бюджеты`,
          path: `/projects/${financeCenter.project!.id}/budgets`,
        },
        {
          variant: 'title',
          title: `Бюджет - ${t('FINANCE_CENTER_TYPES.' + financeCenter.type)} ${financeCenter.name}`,
        },
      ],
      `/projects/${financeCenter.project!.id}/budgets`,
    )
  }

  // function getAvailableRelationships(category: TOperationCategory, relationships: IReportRelationshipItem[]) {
  // 	let targetArray =
  // 		relationships
  // 			?.map(relationship => {
  // 				return {
  // 					label: relationship.subBill.name + "",
  // 					...relationship,
  // 				};
  // 			})
  // 			.filter(relationship => {
  // 				if (getQueryParam(history, "budgetType") == "bdr") {
  // 					if (category == "INCOME") {
  // 						return (
  // 							relationship.type == "INCOME" || relationship.type == "OTHER_INCOMES"
  // 						);
  // 					} else {
  // 						return relationship.type != "INCOME" && relationship.type != "OTHER_INCOMES"
  // 					}
  // 				} else {
  // 					if (relationship.articles[1]?.flow == "OPERATIONS") {
  // 						return;
  // 					}
  // 					if (category == "INCOME") {
  // 						return relationship.articles[1]?.type == "INCOME" || relationship.articles[1]?.type == "OTHER_INCOMES";
  // 					} else {
  // 						return relationship.articles[1]?.type != "INCOME" && relationship.articles[1]?.type != "OTHER_INCOMES"
  // 					}
  // 				}
  // 			}) || [];
  //
  // 	if (getQueryParam(history, "budgetType") == "bdds") {
  // 		return targetArray.sort((a, b) => {
  // 			if (a.articles[0].name > b.articles[0].name) {
  // 				return 1;
  // 			}
  // 			else if (a.articles[0].name == b.articles[0].name) {
  // 				return 0;
  // 			}
  // 			else {
  // 				return -1;
  // 			}
  // 		});
  // 	}
  // 	else {
  // 		return targetArray.sort((a, b) => {
  // 			if (a.type > b.type) {
  // 				return 1;
  // 			}
  // 			else if (a.type == b.type) {
  // 				return 0;
  // 			}
  // 			else {
  // 				return -1;
  // 			}
  // 		});
  // 	}
  // }

  const changePayTempl = useCallback((paymentTemplateID, recordID) => {
    setState((prevState) => {
      return {
        ...prevState,
        subBillRecordList: [
          ...prevState.subBillRecordList!.map((elem) => {
            if (elem.id === recordID) {
              return { ...elem, paymentTemplateID: paymentTemplateID }
            }
            return elem
          }),
        ],
      }
    })
  }, [])

  const changeNds = useCallback((newCpTax, recordID) => {
    setState((prevState) => {
      return {
        ...prevState,
        subBillRecordList: [
          ...prevState.subBillRecordList!.map((elem) => {
            if (elem.id === recordID) {
              return { ...elem, cpTax: newCpTax }
            }
            return elem
          }),
        ],
      }
    })
  }, [])

  const handleUploadDrawerOpen = () => {
    setIsUploadDrawerOpen(true)
  }

  const updateDownloadButtonDisabled = (value: boolean) => {
    setIsDownloadButtonDisabled(value)
  }

  const isCopyButtonDisabled = () => {
    if (state.financeCenter?.fillingMode === 'UNDEFINED') return true
    if ((state.financeCenter?.budgetYears?.length ?? 0) <= 1) return true
    if (state.tableRowCount === 0) return true
    return false
  }

  const updateTableRowCount = (count: number) => {
    setState((prevState) => {
      return {
        ...prevState,
        tableRowCount: count,
      }
    })
  }

  const toggleStateRefresh = () => {
    setState((prevState) => ({
      ...prevState,
      refresh: !prevState.refresh,
    }))
  }

  React.useEffect(() => {
    if (company?.userCompanyName === 'Черное море') {
      changeQueryParams(history, { subcategory: 'OPERATING_EXPENSES' })
    }
  }, [company, history])
  // console.log(state);
  return (
    <Box>
      {/* <Backdrop open={isBlocking} /> */}
      <YearProlongationForm
        open={state.isProlongationFormOpen}
        onClose={(e, r) => openProlongationForm(false)}
        variant="finCenter"
        finCenterState={{
          financeCenter: state.financeCenter,
          project: state.financeCenter?.project || null,
          refresh: state.refresh,
        }}
        setFinCenterState={setState as React.Dispatch<React.SetStateAction<Partial<IFinanceCenterState>>>}
        onCancel={() => openProlongationForm(false)}
      />
      <CopyBudgetDialog
        financeCenterID={Number(financeCenterID)}
        projectID={Number(projectID)}
        category={category}
        hasDataInTable={state.tableRowCount > 0}
        isOpen={state.isCopyDialogOpen}
        availableYears={state.financeCenter?.budgetYears}
        currentYear={Number(currentYear)}
        onClose={handleCopyDialogToggle(false)}
        toggleStateRefresh={toggleStateRefresh}
      />

      {!state.isSubBillAssignMode && (
        <ControlPanel.Wrapper
          sx={{
            flexWrap: 'wrap !important',
            pb: 0,
            pt: '10px',
            '@media (max-width: 2030px)': {
              display: 'grid',
              gridTemplateColumns: `minmax(${category === 'TOTAL' ? 590 : 520}px, 1fr) auto`,
              alignItems: 'flex-start',
            },
          }}>
          <ControlPanel.InnerContainer
            align="left"
            sx={{
              pb: '10px',
              ...(budgetType !== 'ptt'
                ? {
                    '@media (max-width: 2030px)': {
                      flexWrap: 'wrap',
                      rowGap: '10px',
                    },
                  }
                : {}),
            }}>
            <BudgetTypeSelect
              project={state.project}
              financeCenter={state.financeCenter}
              isSubBillAssignMode={state.isSubBillAssignMode}
            />
            {state.financeCenter && (
              <BudgetControls
                variant="finCenter"
                availableYears={state.financeCenter?.budgetYears}
                hideReportType={budgetType === 'ptt'}
              />
            )}
          </ControlPanel.InnerContainer>
          {budgetType !== 'ptt' && (
            <BudgetButtons
              category={category}
              budgetType={budgetType}
              project={state.project}
              financeCenter={state.financeCenter}
              isSubBillAssignMode={state.isSubBillAssignMode}
              isDownloadDisabled={isDownloadButtonDisabled}
              isCopyButtonDisabled={isCopyButtonDisabled()}
              // createOperation={createOperation}
              onUploadDrawerOpen={handleUploadDrawerOpen}
              openProlongationForm={openProlongationForm}
              onCopyDialogOpen={handleCopyDialogToggle(true)}
              switchSubBillAssignMode={switchSubBillAssignMode}
            />
          )}
        </ControlPanel.Wrapper>
      )}
      {budgetType !== 'ptt' && !state.isSubBillAssignMode && company && state.project && (
        <ControlPanel.Wrapper py={'10px'} justifyContent={'space-between'}>
          <ControlPanel.InnerContainer align="left" m={0}>
            <CategoryTabs
              project={state.project}
              category={category}
              budgetType={budgetType}
              financeCenter={state.financeCenter}
              isSubBillAssignMode={state.isSubBillAssignMode}
              setState={setState}
            />
            {budgetType === 'bdr' &&
              category !== 'TOTAL' &&
              company?.userCompanyName !== 'Черное море' &&
              state.project?.type !== 'INVEST' && <SubcategoryTabs category={category} setState={setState} />}
          </ControlPanel.InnerContainer>

          {category !== 'TOTAL' && (
            <ControlPanel.InnerContainer align="right" m={0} sx={{ gap: '20px', whiteSpace: 'nowrap' }}>
              <Subaccount financeCenter={state.financeCenter} />
              {reportType === 'MONTHLY' && <CreateNextRowCheckbox />}
            </ControlPanel.InnerContainer>
          )}
        </ControlPanel.Wrapper>
      )}

      {category !== 'TOTAL' &&
        budgetType !== 'ptt' &&
        !state.isSubBillAssignMode &&
        state.ESNParams &&
        !isOperationsLoading &&
        state.financeCenter && (
          <FinanceCenterRecords
            financeCenter={state.financeCenter}
            ESNParams={state.ESNParams}
            deleteOperation={deleteOperation}
            total={state?.total}
            operations={state?.operations}
            recalculateTotal={recalculateTotal}
            fillingMode={state.financeCenter.fillingMode}
            updateDownloadButtonDisabled={updateDownloadButtonDisabled}
            onCopyDialogOpen={handleCopyDialogToggle(true)}
            availableYears={state.financeCenter?.budgetYears}
            updateTableRowCount={updateTableRowCount}
          />
        )}
      {state.isSubBillAssignMode && state.operations && (
        <SubBillAssignMode
          operationType="finCenter"
          open={state.isSubBillAssignMode}
          onClose={switchSubBillAssignMode}
          budgetType={budgetType as TBudgetType}
          operations={state.operations}
          autocompleteOptions={getAvailableRelationships(
            category as TOperationCategory,
            state.availableRelationships as IReportRelationshipItem[],
            history,
          )}
          AutocompleteProps={{
            groupBy: (option) =>
              budgetType === 'bdr'
                ? t(`BDR_ARTICLE_CATEGORY.` + String(option.articles[0]?.type))
                : option.articles[0].name,
            loading: state.availableRelationships === null,
            loadingText: <CircularProgress sx={{ position: 'relative' }} />,
          }}
        />
      )}
      {budgetType === 'ptt' && (state.subBillRecordList ?? []).length > 0 && (
        <PTTtable
          changePayTempl={changePayTempl}
          changeNds={changeNds}
          financeCenterID={state.financeCenter?.id!}
          subBillRecordList={state.subBillRecordList!}></PTTtable>
      )}
      {category === 'TOTAL' && state.totalReport && state.totalReport!.data.children.length > 0 && (
        <Report.Wrapper financeCenters={state.financeCenter ? [state.financeCenter?.id] : []}>
          <FinCenterTableHead />
          {state.totalReport && <Report.Body data={state.totalReport?.data} />}
          {state.totalReport && <Report.Saldo saldo={state.totalReport.saldo} />}
        </Report.Wrapper>
      )}

      {budgetType === 'ptt' && !state.subBillRecordList && <CircularProgress />}
      {budgetType !== 'ptt' && !state.operations && !state.totalReport && <CircularProgress />}
      {budgetType === 'ptt' && state.subBillRecordList && state.subBillRecordList.length === 0 && (
        <HelpPlug.Wrapper
          sx={{
            height: 'calc(100vh - 195px)',
          }}>
          <HelpPlug.Title>
            Для отображения условий оплат и налогов необходимо
            <br />
            заполнить раздел "Финансовая деятельность"
          </HelpPlug.Title>
          <HelpPlug.Image />
        </HelpPlug.Wrapper>
      )}

      {budgetType !== 'ptt' && category === 'TOTAL' && !state.totalReport?.data.children.length && (
        <HelpPlug.Wrapper
          sx={{
            height: 'calc(100vh - 195px)',
          }}>
          <HelpPlug.Title>Нет данных</HelpPlug.Title>
          <HelpPlug.Image />
        </HelpPlug.Wrapper>
      )}

      {/* {((budgetType !== 'ptt' && state.totalReport && state.totalReport.data.children.length === 0) ||
                (budgetType !== 'ptt' && state.operations && state.operations.length === 0)) && (
                <BudgetStartPage />
            )} */}

      <UploadFileDrawer
        changeBlocking={changeBlocking}
        refresh={refreshOperation}
        DrawerProps={{
          open: isUploadDrawerOpen,
          onClose: () => setIsUploadDrawerOpen(() => false),
        }}
        DownloadTemplateComponent={() => (
          <DefaultDownloadTemplate
            onClick={() =>
              BudgetAPI.downloadTeplateXLS(
                new URLSearchParams(history.location.search).get('category') as string,
                new URLSearchParams(history.location.search).get('financeCenterID') as string,
                projectID,
              )
            }
          />
        )}
        ApiProps={{
          id: projectID,
          upload: {
            type: 'bdr_budget_import',
            id: projectID,
            bodyJsonProps: {
              param: {
                projectID: projectID,
                financeCenterID: financeCenterID,
                category: new URLSearchParams(history.location.search).get('category') as string,
              },
            },
          },
          upload_cancel: {
            type: 'bdr_budget_import_cancel ',
            id: projectID,
            bodyJsonProps: {
              projectID: projectID,
              financeCenterID: financeCenterID,
              category: new URLSearchParams(history.location.search).get('category') as string,
            },
          },
        }}
      />
    </Box>
  )
}
