import { Tab, Tabs } from '@mui/material'
import React, { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { changeQueryParams, getOperationCategoriesByBudgetType, getQueryParam } from '../../../../../global/functions'
import {
  TBudgetType,
  TFinanceOperationsCategory,
  TMonthName,
  TOperationalActivityCategory,
  TReportType,
} from '../../../../../global/types/commos-def'
import { Subcategory } from '../SubcategoryTabs/SubcategoryTabs'
import { ICategoryTabsProps } from './CategoryTabs.types'
import { useGetCompanyQuery } from '../../../../../api/profile'

const SUBCATEGORY_BY_CATEGORY: Record<string, string> = {
  INCOME: Subcategory.INCOME,
  OPERATING_EXPENSES: Subcategory.WAGE_FUND,
}

const OPERATING_EXPENSES_VALUES = ['WAGE_FUND', 'STAFF_MAINTENANCE', 'OPERATING_EXPENSES']
const OPERATING_INCOME_VALUES = ['INCOME', 'OTHER_INCOME']

export const CategoryTabs: React.FC<ICategoryTabsProps> = ({
  project,
  category,
  budgetType,
  financeCenter,
  isSubBillAssignMode,
  setState,
}) => {
  const { t } = useTranslation('ENUMS')
  const history = useHistory()
  const reportType = getQueryParam(history, 'reportType')
  const monthStart = getQueryParam(history, 'monthStart')
  const monthEnd = getQueryParam(history, 'monthEnd')
  const yearStart = getQueryParam(history, 'yearStart')

  const { data: company } = useGetCompanyQuery()

  const getTabsValue = () => {
    if (!category) return null
    if (OPERATING_EXPENSES_VALUES.includes(category as string)) return 'OPERATING_EXPENSES'
    if (OPERATING_INCOME_VALUES.includes(category as string)) return 'INCOME'
    return category
  }

  const changeCategory = (_: SyntheticEvent<Element, Event>, category: string) => {
    setState((prev) => ({ ...prev, operations: null }))

    setTimeout(() => {
      changeQueryParams(history, {
        category:
          company?.userCompanyName === 'Черное море' || project?.type === 'INVEST'
            ? category
            : category === 'OPERATING_EXPENSES'
            ? 'WAGE_FUND'
            : category,
        subcategory:
          company?.userCompanyName === 'Черное море' || project?.type === 'INVEST'
            ? 'OPERATING_EXPENSES'
            : SUBCATEGORY_BY_CATEGORY[category],
        reportType: reportType === 'YEARLY' ? 'MONTHLY' : (reportType as TReportType),
        monthStart: reportType === 'YEARLY' ? 'JANUARY' : (monthStart as TMonthName),
        monthEnd: reportType === 'YEARLY' ? 'DECEMBER' : (monthEnd as TMonthName),
        yearEnd:
          category === 'TOTAL' && reportType === 'YEARLY'
            ? financeCenter?.budgetYears[financeCenter?.budgetYears.length - 1].toString()
            : (yearStart as string),
      })
    }, 0)
  }

  if (isSubBillAssignMode && category === 'TOTAL') return null

  const checkTabForDisable = (item: any, budgetType: string | null) => {
    if (budgetType === 'bdds') {
      if (item === 'PAYMENTS' && financeCenter?.finExpenseSubBill === null) return true

      if (item === 'INCOME' && financeCenter?.finIncomeSubBill === null) return true
    } else {
      if (item === 'OPERATING_EXPENSES' && financeCenter?.expenseSubBill === null) return true

      if (item === 'INCOME' && financeCenter?.incomeSubBill === null) return true
    }

    return false
  }

  return (
    <Tabs
      value={getTabsValue()}
      onChange={changeCategory}
      sx={{
        mr: 2,
      }}
    >
      {(
        getOperationCategoriesByBudgetType(budgetType as TBudgetType) as
          | TOperationalActivityCategory[]
          | TFinanceOperationsCategory[]
      ).map((item: string) => {
        if (
          company?.userCompanyName === 'Черное море' &&
          (category === 'OPERATING_EXPENSES' || category === 'TOTAL') &&
          item === 'INCOME'
        ) {
          return undefined
        }
        if (
          project?.type === 'INVEST' &&
          (category === 'OPERATING_EXPENSES' || category === 'TOTAL') &&
          budgetType === 'bdr' &&
          item === 'INCOME'
        ) {
          return undefined
        }
        const translateItem = t(`${budgetType?.toUpperCase()}_OPERATION_CATEGORIES.` + item)
        const label =
          company?.userCompanyName === 'Черное море'
            ? translateItem.toLowerCase() === 'расходы'
              ? 'КАПВЛОЖЕНИЯ'
              : translateItem
            : project?.type === 'INVEST' && budgetType === 'bdr' && translateItem.toLowerCase() === 'расходы'
            ? financeCenter?.billType || translateItem
            : translateItem
        return (
          <Tab
            key={item}
            label={label}
            value={item}
            disabled={checkTabForDisable(item, budgetType)}
            sx={{
              letterSpacing: '0.46px !important',
              lineHeight: '22px !important',
            }}
          />
        )
      })}
    </Tabs>
  )
}
