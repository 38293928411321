import qs from 'qs'
import {
  IFinanceCenter,
  IPrPrEstimateOperation,
  IProjectInfo,
  IReportRelationshipItem,
  TEstimateType,
} from '../../global/types/commos-def'
import { History } from 'history'
import { ProductionProgramAPI } from '../../global/api/APIMethods/ProductionProgramAPI'
import { EstimateOperation, EstimateTotal } from '../../global/api/constructors'
import { Params } from '../../global/types/params-def'
import { IProductionProgramQueryParams, IProductionProgramState } from './ProductionProgram-def'
import { IProductionProgramEstimateGetListQuery } from '../../global/api/definitions'

export function getAvailableRelationships(
  contractType: 'genContract' | 'subContract',
  relationships: IReportRelationshipItem[],
) {
  return (
    relationships
      ?.map((relationship) => {
        return {
          label: relationship.subBill.name + '',
          ...relationship,
        }
      })
      .filter((relationship) => {
        if (contractType == 'genContract') {
          return (
            relationship.articles[0]?.type == 'INCOME' || relationship.articles[0]?.type == 'OTHER_INCOMES_OUTCOMES'
          )
        } else {
          return relationship.articles[0]?.type != 'INCOME'
        }
      })
      .sort((a, b) => {
        if (a.gostBill.number > b.gostBill.number) {
          return 1
        } else if (a.gostBill.number == b.gostBill.number) {
          return 0
        } else {
          return -1
        }
      }) || []
  )
}

export function isProlongationAvailable(project: IProjectInfo, financeCenter: IFinanceCenter) {
  let projectStartYear = Number(project.dateStart.split('.')[2])
  let projectEndYear = Number(project.dateEnd.split('.')[2])
  let financeCenterLastYear = financeCenter.budgetYears[financeCenter.budgetYears.length - 1]
  if (financeCenterLastYear > projectEndYear) {
    return false
  }
  if (projectStartYear != projectEndYear && financeCenterLastYear != Number(projectEndYear)) {
    return true
  }
  return false
}

export function getPlanningAndAnalitics(
  history: History,
  setState: React.Dispatch<React.SetStateAction<IProductionProgramState>>,
) {
  let targetQuery = qs.parse(history.location.search, { ignoreQueryPrefix: true })
  targetQuery.pageNumber = undefined
  targetQuery.limitRow = undefined
  ProductionProgramAPI.getPlanningAndAnalitics(targetQuery).then((data) => {
    setState((prevState) => ({
      ...prevState,
      operations: data?.operations,
      total: data?.total,
      totalRecords: data?.totalRecords,
      recalculatedTotal: prevState.operations && prevState.operations.length == 0 ? new EstimateTotal() : data?.total,
    }))
  })
}

export function getEstimate(history: History, setState: React.Dispatch<React.SetStateAction<IProductionProgramState>>) {
  let targetQuery = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  }) as unknown as IProductionProgramQueryParams
  const { projectID, financeCenterID, estimateType, limit, offset } = targetQuery

  ProductionProgramAPI.getEstimate({
    projectID: projectID,
    financeCenterID: financeCenterID,
    estimateType: estimateType as TEstimateType,
    analysisType: 'PLAN',
    limit: limit,
    offset: offset,
  }).then((data) => {
    setState((prevState) => ({
      ...prevState,
      operations: data?.data,
      totalRecords: data?.totalRecords,
      total: prevState.operations && prevState.operations.length == 0 ? new EstimateTotal() : data?.total,
      recalculatedTotal: prevState.operations && prevState.operations.length == 0 ? new EstimateTotal() : data?.total,
    }))
  })
}

export function createOperation(
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  history: History,
  previousOperationID: number | null,
  setState: React.Dispatch<React.SetStateAction<IProductionProgramState>>,
) {
  e.stopPropagation()
  let newOperation = new EstimateOperation()

  if (e.detail == 1) {
    ProductionProgramAPI.createOperation(newOperation, {
      ...(qs.parse(history.location.search, {
        ignoreQueryPrefix: true,
      }) as unknown as IProductionProgramEstimateGetListQuery),
      analysisType: 'PLAN',
      beforeEstimateID: previousOperationID ? String(previousOperationID) : null,
    }).then((data) => {
      newOperation.id = data
      setState((prevState) => ({
        ...prevState,
        operations: [newOperation, ...(prevState.operations || [])] as IPrPrEstimateOperation[],
      }))
    })
  }
}
