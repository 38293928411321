import BackupIcon from '@mui/icons-material/Backup'
import DownloadingIcon from '@mui/icons-material/Downloading'
import ErrorsListDrawer from '../../components/_NEW/ErrorsListDrawer'
import FactUploadDrawer from './components/FactUploadDrawer'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import UploadAlert from './components/UploadAlert/UploadAlert'
import useConfirmDialog, { UseExitConfirmProps } from '../../hooks/useConfirmDialog'
import { ControlPanel } from '../../components/ControlPanel/ControlPanel'
import { DownloadHistory } from './DownloadHistory'
import { factGenerateTable } from './Fact.generateTable'
import { HelpPlug } from '../../components/HelpPlug/HelpPlug'
import { InterfaceContext } from '../../contexts/context.interface'
import { Params } from '../../global/types/params-def'
import { profileSelector } from '../../store/slices/profile'
import { ProjectsAPI } from '../../global/api/APIMethods/ProjectsAPI'
import { STYLED } from '../../global/styles/presets'
import { socketChanel } from '../../global/api/socket'
import { StyledAgGrid } from '../../components/TableAGGrid/StyledAgGrid/StyledAgGrid'
import { StyledSelect, StyledSelectMenuItem, StyledTabs } from './styles'
import { successHandler } from '../../global/api/api'
import { theme } from '../../global/styles/theme'
import { useParams } from 'react-router-dom'
import { useTypedSelector } from '../../store/store'
import { WrapperTableAGGridFact } from './Fact.styles'
import {
  IProjectInfo,
  IOperations,
  TFactType,
  IDownloadsItem,
  ExcelParseError,
  UploadType,
} from '../../global/types/commos-def'
import { Box, FormControl, SelectChangeEvent, Stack, Tab, Typography } from '@mui/material'

export interface IFactProps {
  operations: IOperations[]
  handleChangeOperation: (event: SelectChangeEvent<unknown>) => void
  factType: TFactType
  setFactType: (value: TFactType) => void
  onDeleteFact: (id: number) => void
  connectWS: () => void
  handleRefresh: () => void
  project: IProjectInfo
  downloadsList: IDownloadsItem[]
  typeTable: 'DOWNLOAD' | 'TABLE'
  setTypeTable: (value: 'DOWNLOAD' | 'TABLE') => void
  socketChanel?: WebSocket
}

export const Fact: React.FC<IFactProps> = ({
  operations,
  downloadsList,
  handleChangeOperation,
  factType,
  typeTable,
  setTypeTable,
  project,
  onDeleteFact,
  connectWS,
  handleRefresh,
  setFactType /* socketChanel */,
}) => {
  const profile = useTypedSelector(profileSelector)
  const interfaceCTX = useContext(InterfaceContext)
  const { firstName } = profile
  const { projectID, financeCenterID } = useParams() as Params

  // const socketChanel = interfaceCTX.websocket;

  //************************************************************************************ */
  const handleChangeTable = React.useCallback((value: 'DOWNLOAD' | 'TABLE') => {
    setTypeTable(value)
  }, [])

  let sumTotal = 0
  if (operations) {
    sumTotal = operations?.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0)
  }

  React.useEffect(() => {
    initiateBreadcrumbs(project)
    const authWS = {
      bodyJson: JSON.stringify({ token: auth.token }),
      id: projectID,
      type: 'auth',
    }

    socketChanel.send(JSON.stringify(authWS))

    socketChanel.onmessage = function (event) {
      let status = JSON.parse(event.data).type
      if (status === 'status_ok') {
        console.log('[socketChanel-open] Соединение открыто')
      }
      if (status === 'error') {
        let bodyJson = JSON.parse(event.data).bodyJson
        let msg = JSON.parse(bodyJson).msg
        console.log(`[socketChanel-error] Не авторизован, msg:${msg}`)
      }
    }
    socketChanel.onclose = function (event) {
      if (event.wasClean) {
        console.log(`[socketChanel-close] Соединение закрыто, причина=${event.reason}.`)
      } else {
        console.log(`[socketChanel-close] Соединение прервано, причина=${event.reason}.`)
      }
    }
  }, [])

  function initiateBreadcrumbs(project: IProjectInfo) {
    interfaceCTX.setBreadcrumbsProps([
      {
        variant: 'link',
        title: `Проект ${project.name}`,
        path: `/projects/${project.id}/menu`,
      },
      {
        variant: 'title',
        title: `Факт`,
      },
    ])
  }

  /****************************************** Drawer ********************************************/
  const [isUploaded, setUploaded] = React.useState<UploadType>('start')
  const [closeUpload, setCloseUpload] = useState<boolean>(false)
  const [errorList, setErrorList] = useState<ExcelParseError[]>([])

  const onUploadDrawerOpen = useCallback(() => {
    setIsDrawerOpen(true)
  }, [])

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    immediately || !dirty ? setIsDrawerOpen(false) : openConfirm()
  }, [])

  /****************************************** ConfirmDialog ********************************/

  const handleConfirm = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        if (isUploaded === 'error') {
          setIsDrawerOpen(false)
          setUploaded('start')
        }
        if (isUploaded === 'loading' && closeUpload) onCancelFactUpload()
        if (isUploaded === 'loading' && !closeUpload) setIsDrawerOpen(false)
        if (isUploaded === 'start') setIsDrawerOpen(false)
      }
    },
    [isUploaded, closeUpload],
  )

  let body
  let title

  switch (true) {
    case !closeUpload && isUploaded === 'loading':
      body = 'Форма будет закрыта, а загрузка данных продолжится!'
      break
    case isUploaded === 'loading' && closeUpload:
      title = 'Вы уверены, что хотите отменить загрузку файла?'
      body = (
        <Typography variant="body2" component="p" align="center" sx={{ maxWidth: '600px' }}>
          Загрузка файла будет отменена. Факт останется без изменнений{' '}
        </Typography>
      )
      break
    default:
      body = undefined
      break
  }

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
    body,
  }

  const { ConfirmDialog, openConfirm, onClose: onCloseConfirm } = useConfirmDialog(dataForConfirmDialog)

  React.useEffect(() => {
    if (isUploaded === 'success' || isUploaded === 'error') onCloseConfirm()
  }, [isUploaded])

  /****************************************** Drawer  Upload**************************************/

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [file, setFile] = useState<File>()

  const onCancelFactUpload = useCallback(() => {
    if (socketChanel.readyState === 3) {
      // console.log('onCancelFactUpload-readyState: ', socketChanel.readyState);
    }
    const msgWS = {
      type: 'cancel_upload_excel_fact',
      id: projectID,
      bodyJson: JSON.stringify({
        projectID: projectID,
        financeCenterID: financeCenterID,
        factType: factType,
      }),
    }
    socketChanel.send(JSON.stringify(msgWS))

    socketChanel.onmessage = function (event) {
      let status = JSON.parse(event.data).type
      if (status === 'canceled') {
        setUploaded('canceled')
      }
      if (status === 'error') {
        let bodyJson = JSON.parse(event.data).bodyJson
        let msg = JSON.parse(bodyJson).msg
      }
    }
  }, [socketChanel])

  /****************************************** websocket *************************************************/

  const auth = JSON.parse(localStorage.getItem('auth') || '{}')

  const [progressTime, setProgressTime] = React.useState<string>('')
  const [progressPercent, setProgressPercent] = React.useState<number>(0)
  const [uploadRecords, setUploadRecords] = useState<number>(0)

  if (socketChanel)
    socketChanel.onmessage = function (event) {
      // setUploaded('loading');
      let status = JSON.parse(event.data).type
      let syncId = JSON.parse(event.data).id

      if (status === 'upload_progress' && Number(projectID) === syncId) {
        setUploaded('loading')
        let bodyJson = JSON.parse(event.data).bodyJson
        let remainTime = JSON.parse(bodyJson).remainTime
        setProgressTime!(String(remainTime))
        let progress = JSON.parse(bodyJson).progress
        setProgressPercent!(Number(progress))
        let totalRecords = Number(JSON.parse(bodyJson).total)
        setUploadRecords!(totalRecords)
      }

      if (status === 'status_ok' && isUploaded === 'loading') {
        setUploaded('success')
        handleRefresh()
        successHandler('Файл успешно загружен')
      }
      if (status === 'canceled') {
        setUploaded('canceled')
      }

      if (status === 'error') {
        let formData = new FormData()
        formData.append('file', file!)
        ProjectsAPI.uploadTemplateFact(projectID, factType, formData!)
          .then(({ success, error, errorList }) => {
            if (!success) {
              setErrorList(errorList!)
              setUploaded('error')
            }
          })
          .catch((error) => {
            const errorCustom: ExcelParseError = {
              cell: '',
              col: 0,
              row: 0,
              message: String(error),
              type: 'invalid_cell_value',
            }
            setErrorList([errorCustom])
            setUploaded('error')
          })
      }
    }

  /****************************************** Errors List Drawer *************************************************/

  const [isErrorsListDrawerOpen, setIsErrorsListDrawerOpen] = useState<boolean>(false)

  const onErrorsListDrawerOpen = useCallback((errorList: ExcelParseError[]) => {
    setErrorList(errorList)
    setIsErrorsListDrawerOpen(true)
  }, [])

  const onErrorsListDrawerClose = useCallback(() => {
    setIsErrorsListDrawerOpen(false)
  }, [])

  const dataOperationsListMemo: IOperations[] = useMemo(() => {
    return operations?.map((item) => item)
  }, [operations])

  return (
    <Box>
      <ControlPanel.Wrapper sx={{ paddingBottom: '10px' }}>
        <ControlPanel.InnerContainer
          align="left"
          sx={{ justifyContent: 'space-between', width: '100%', minHeight: '36px' }}
        >
          <Stack spacing={1} direction="row">
            <FormControl
              style={{ width: '130px' }}
              sx={{
                '& .MuiSelect-outlined': {
                  fontSize: '13px !important',
                  // lineHeight: '22px !important',
                  textAlign: 'left !important',
                  color: `${theme.palette.primary.main} !important`,
                  textTransform: 'uppercase',
                  fontWeight: 500,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${theme.palette.primary.main} !important`,
                  borderRadius: '4px !important',
                },
                '& .MuiSvgIcon-root': {
                  right: '14px !important',
                  color: `${theme.palette.primary.main}`,
                },
              }}
            >
              <StyledSelect value={factType} onChange={handleChangeOperation}>
                <StyledSelectMenuItem value={'TRANSACTIONS'}>{'ПРОВОДКИ'}</StyledSelectMenuItem>
                <StyledSelectMenuItem value={'EXTRACTS'}>{'ВЫПИСКИ'}</StyledSelectMenuItem>
              </StyledSelect>
            </FormControl>
            <StyledTabs
              sx={STYLED.TABS.OUTLINED}
              value={typeTable}
              onChange={(e, value: 'TABLE' | 'DOWNLOAD') => handleChangeTable(value)}
            >
              <Tab label="история загрузок" value="DOWNLOAD"></Tab>
              <Tab label="сводная таблица" value="TABLE"></Tab>
            </StyledTabs>
          </Stack>
          {operations.length > 0 && (
            <Stack direction="row" spacing={2}>
              {typeTable === 'DOWNLOAD' && (
                <Stack direction="row" spacing={2}>
                  <ControlPanel.Btn
                    sx={{ lineHeight: '22px', width: '121px', height: '36px' }}
                    startIcon={<DownloadingIcon />}
                    onClick={onUploadDrawerOpen}
                  >
                    Загрузить
                  </ControlPanel.Btn>
                </Stack>
              )}
              {typeTable === 'TABLE' && (
                <Stack direction="row" spacing={2}>
                  <ControlPanel.Btn
                    sx={{ lineHeight: '22px', width: '105px', height: '36px' }}
                    startIcon={<FileDownloadIcon />}
                    disabled /* onClick={onDownloadFormClick} */
                  >
                    Скачать
                  </ControlPanel.Btn>
                </Stack>
              )}
            </Stack>
          )}
        </ControlPanel.InnerContainer>
      </ControlPanel.Wrapper>
      {typeTable === 'DOWNLOAD' ? (
        downloadsList && downloadsList.length > 0 ? (
          <DownloadHistory downloadsList={downloadsList} onDeleteFact={onDeleteFact} />
        ) : (
          <HelpPlug.Wrapper height={'calc(100vh - 120px)'}>
            <HelpPlug.Title>
              Здравствуйте, {firstName}, у Вас нет записей факта по этому проекту, <br />
              Вы можете внести первые данные, нажав на кнопку ниже.
            </HelpPlug.Title>
            <HelpPlug.Btn onClick={onUploadDrawerOpen} startIcon={<BackupIcon />}>
              Загрузить
            </HelpPlug.Btn>
            <HelpPlug.Image />
            {/* <HelpPlug.HelpCenterLink openUserGuide={() => { }} /> */}
          </HelpPlug.Wrapper>
        )
      ) : operations.length > 0 ? (
        <Box pr={'10px'} pl={2.5}>
          <WrapperTableAGGridFact>
            <StyledAgGrid rowData={operations} columnDefs={factGenerateTable.columnDefs()} rowHeight={72} />
          </WrapperTableAGGridFact>
        </Box>
      ) : (
        <HelpPlug.Wrapper height={'calc(100vh - 120px)'}>
          <HelpPlug.Title>
            Здравствуйте, {firstName}, у Вас нет записей факта по этому проекту, <br />
            Вы можете внести первые данные, нажав на кнопку ниже.
          </HelpPlug.Title>
          <HelpPlug.Btn onClick={onUploadDrawerOpen} startIcon={<BackupIcon />}>
            Загрузить
          </HelpPlug.Btn>
          <HelpPlug.Image />
          {/* <HelpPlug.HelpCenterLink openUserGuide={() => { }} /> */}
        </HelpPlug.Wrapper>
      )}
      {isDrawerOpen && (
        <FactUploadDrawer
          open={isDrawerOpen}
          onClose={onDrawerClose}
          projectName={project.name}
          handleRefresh={handleRefresh}
          connectWS={connectWS}
          factType={factType}
          setFactType={setFactType}
          onErrorsListDrawerOpen={onErrorsListDrawerOpen}
          setFile={setFile}
          file={file!}
          downloadsList={downloadsList}
          setUploaded={setUploaded}
          isUploaded={isUploaded}
          setCloseUpload={setCloseUpload}
          setErrorList={setErrorList}
          errorList={errorList!}
          setProgressTime={setProgressTime}
          setProgressPercent={setProgressPercent}
          progressPercent={progressPercent}
          progressTime={progressTime}
          uploadRecords={uploadRecords}
          setUploadRecords={setUploadRecords}
          // socketChanelState={socketChanel}
        />
      )}
      {errorList.length > 0 && (
        <ErrorsListDrawer open={isErrorsListDrawerOpen} onClose={onErrorsListDrawerClose} errorList={errorList} />
      )}
      <ConfirmDialog />
      {isUploaded !== 'start' && !isDrawerOpen && (
        <UploadAlert
          isDrawerOpen={isDrawerOpen}
          isUploaded={isUploaded}
          onClickDrawerOpen={onUploadDrawerOpen}
          setUploaded={setUploaded}
          progressTime={progressTime}
          progressPercent={progressPercent}
          handleRefresh={handleRefresh}
          // socketChanelState={socketChanel}
          factType={factType}
          setErrorList={setErrorList}
          file={file!}
        />
      )}
    </Box>
  )
}

/* <TableFact.Total
              total={sumTotal.toLocaleString('ru-RU', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            />

<TableFact.Wrapper style={{ height: 'calc(100vh - 140px)' }}>
              <TableFact.Headings />
                <TableBody>
               {operations?.map((transaction) => {
                return (
                  <TableFact.Row
                    key={transaction.id}
                    operation={transaction}
                  />
                );
              })}
               </TableBody>
                <TableFact.Total
              total={sumTotal.toLocaleString('ru-RU', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            />
</TableFact.Wrapper>


 <div className={style['scroll-table']}>
              <Table style={{ borderCollapse: 'separate', paddingRight: '7px' }}>
                <thead>
                  <StyledHeadRow >
                    <StyledHeadTd sx={{ textAlign: "left", width: '101px' }}>Дата</StyledHeadTd>
                    <StyledHeadTd sx={{ textAlign: "left", width: '267px' }}>Конрагент</StyledHeadTd>
                    <StyledHeadTd sx={{ textAlign: "left", width: '415px' }}>Назначение платежа</StyledHeadTd>
                    <StyledHeadTd sx={{ textAlign: "left", width: '225px' }}>ЦФО</StyledHeadTd>
                    <StyledHeadTd style={{ textAlign: "center", width: '116px' }}>Счет</StyledHeadTd>
                    <StyledHeadTd sx={{ textAlign: "left", width: '415px' }}>Субсчет</StyledHeadTd>
                    <StyledHeadTd sx={{ textAlign: "center", width: '162px' }}>Сумма</StyledHeadTd>
                  </StyledHeadRow>
                </thead>
              </Table>
              <div className={style['scroll-table-body']}>
                /* <table>
                  <tbody>
                    {operations?.map((operation) => {
                      return (
                        <TableFact.Row operation={operation} sx={{ height: '72px', backgroundColor: '#fff' }} />
                      );
                    })}
                  </tbody>
                </table> */
/*  <TableVirtuoso
  style={{ height: "calc(40vh - 130px)" }}
  data={operations}
  components={{
    Scroller: React.forwardRef((props, ref) => <TableContainer
      {...props} ref={ref} sx={{ 'overflow-x': 'hidden' }}
    />),
    Table: (props) => <Table {...props}
      style={{ borderCollapse: 'separate', paddingRight: '6px', tableLayout: 'auto' }} />,
    // TableHead: TableHead,
    TableRow: (props) => <TableRow {...props}
      style={{ height: '72px', backgroundColor: '#fff' }} />,

  }}
  fixedHeaderContent={() => (<TableFact.Headings2 />)}

  itemContent={(index, operation) => {
    return <TableFact.Row operation={operation}  />
  }}
/>
</div>
</div>


<TableFact.Wrapper style={{ height: '400px)' }}>
<TableFact.Headings2 />

<FixedSizeList
  itemData={operations}
  innerElementType="tr"
  outerElementType='TableBody'
  itemCount={operations.length}
  itemSize={72}
  height={document.documentElement.clientHeight}
  width={'100%'}
// ref={listRef}
//style={{ overflow: 'hidden' }}
>
  {({ data, index, style }) => {

    return (
      <TableFact.Row2 operation={data[index]}></TableFact.Row2>
    );
  }}
</FixedSizeList>


</TableFact.Wrapper>
*/
