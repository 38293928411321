import { Box, Button, TableCell, Tooltip, Typography } from '@mui/material'
import { CellRendererWrapper } from './CellRenderer.styles'
import { ICellEditorParams, ICellRendererParams, ITooltipParams } from 'ag-grid-community'
import { Delete, Error, Edit as EditIcon } from '@mui/icons-material'
import { EENumber, IEENumberProps } from '../../editableElements/EENumber'
import { EEString, IEEStringProps } from '../../editableElements/EEString'
import { floatToJustifyContent } from '../functions'
import {
  ICellButton,
  ICellMultipleNumber,
  ICellMultipleNumberEditor,
  ICellMultipleString,
  ICellNumber,
  ICellNumberEditor,
  ICellString,
  ICellStringEditor,
} from './CellRenderer.types'
import { IValidationError } from '../../editableElements/EETableRow'
import { theme } from '../../../global/styles/theme'

export const CellRenderer = {
  Tooltip: function (props: ITooltipParams) {
    const valueToDisplay = props.value.value ? props.value.value : undefined
    function getErrorMessages(value: any) {
      let validationErrorsArr = [] as IValidationError['message'][]
      for (let key in value) {
        if (value[key].isValid == false) {
          validationErrorsArr = [...validationErrorsArr, value[key].message]
        }
      }
      return validationErrorsArr
    }
    function checkValue(value: any) {
      for (let key in value) {
        if (value[key].isValid == false) {
          return true
        }
      }
    }
    return checkValue(valueToDisplay) ? (
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        p={0.5}
        style={{
          backgroundColor: theme.palette.error.main,
        }}
      >
        <Box display="flex" gap={1} alignItems="center">
          <Error fontSize="small" />
          <Typography>Ошибка</Typography>
        </Box>
        {getErrorMessages(valueToDisplay).map((message, index) => {
          return (
            <Box>
              {index + 1}. {message};
            </Box>
          )
        })}
      </Box>
    ) : (
      <></>
    )
  },
  Multiple: {
    String: function (props: ICellMultipleString & ICellRendererParams) {
      return (
        <CellRendererWrapper hideTotal={Boolean(props?.node?.rowPinned) && props.hideTotal} justifyContent={'center'}>
          <TableCell sx={{ borderBottom: 'none', padding: '0' }}>
            {props.value.map((e: IEEStringProps, id: number) => (
              <EEString {...props} name={String(e)} value={String(e)} mode={'view'} key={id} />
            ))}
          </TableCell>
        </CellRendererWrapper>
      )
    },
    Number: function (props: ICellMultipleNumber & ICellRendererParams) {
      return (
        <CellRendererWrapper hideTotal={Boolean(props?.node?.rowPinned) && props.hideTotal} justifyContent={'center'}>
          <TableCell align={props.align} sx={{ borderBottom: 'none', padding: '0' }}>
            {props.value.map((e: IEENumberProps, id: number) => (
              <EENumber {...e} value={e?.value || 0} mode={'view'} key={id} />
            ))}
          </TableCell>
        </CellRendererWrapper>
      )
    },
    NumberEditor: function (props: ICellMultipleNumberEditor & ICellEditorParams) {
      return (
        <CellRendererWrapper hideTotal={Boolean(props?.node?.rowPinned) && props.hideTotal} justifyContent={'center'}>
          <TableCell align={props.align} sx={{ borderBottom: 'none', padding: '0' }}>
            {props.value.map((e: IEENumberProps, id: number) => (
              <EENumber
                {...e}
                value={e?.value || 0}
                NumberFormatProps={{
                  ...e?.NumberFormatProps,
                  onValueChange: (value) => props.onMultipleChangeValue && props.onMultipleChangeValue(props, e, value),
                }}
                mode={props.editableIndexes ? (props.editableIndexes.includes(id) ? 'edit' : 'view') : 'view'}
                key={id}
              />
            ))}
          </TableCell>
        </CellRendererWrapper>
      )
    },
  },
  Button: {
    Edit: function (props: ICellButton & ICellRendererParams) {
      return (
        <CellRendererWrapper
          hideTotal={Boolean(props?.node?.rowPinned) && props.hideTotal}
          justifyContent={floatToJustifyContent(props?.align)}
        >
          <Button
            size="small"
            variant="text"
            onClick={() => {
              props?.onCellClicked && props.onCellClicked(props?.value)
            }}
          >
            <EditIcon fontSize="small" />
          </Button>
        </CellRendererWrapper>
      )
    },
    Delete: function (props: ICellButton & ICellRendererParams) {
      return (
        <CellRendererWrapper
          hideTotal={Boolean(props?.node?.rowPinned) && props?.hideTotal}
          justifyContent={floatToJustifyContent(props?.align)}
        >
          <Button
            color={'error'}
            size="small"
            onClick={() => {
              props?.onCellClicked && props?.onCellClicked(props?.value)
            }}
          >
            <Delete color={'error'} fontSize="small" />
          </Button>
        </CellRendererWrapper>
      )
    },
  },
  Number: function (props: ICellNumber & ICellRendererParams) {
    return (
      <CellRendererWrapper
        hideTotal={Boolean(props?.node?.rowPinned) && props.hideTotal}
        justifyContent={floatToJustifyContent(props.EENumberProps.align)}
      >
        <EENumber {...props?.EENumberProps} value={props?.value} />
      </CellRendererWrapper>
    )
  },
  NumberEditor: function (props: ICellNumberEditor & ICellEditorParams) {
    return (
      <CellRendererWrapper
        hideTotal={Boolean(props?.node?.rowPinned) && props.hideTotal}
        justifyContent={floatToJustifyContent(props.EENumberProps.align)}
      >
        <EENumber
          {...props?.EENumberProps}
          value={props?.value || 0}
          NumberFormatProps={{
            ...props?.EENumberProps.NumberFormatProps,
            onValueChange: (value) => props?.onChangeValue && props?.onChangeValue(props, value),
          }}
        />
      </CellRendererWrapper>
    )
  },
  String: function (props: ICellString & ICellRendererParams) {
    return (
      <CellRendererWrapper
        hideTotal={Boolean(props?.node?.rowPinned) && props?.hideTotal}
        justifyContent={floatToJustifyContent(props?.EEStringProps?.align)}
      >
        <EEString {...props?.EEStringProps} value={props.value} />
      </CellRendererWrapper>
    )
  },
  StringEditor: function (props: ICellStringEditor & ICellEditorParams) {
    return (
      <CellRendererWrapper
        hideTotal={Boolean(props?.node?.rowPinned) && props?.hideTotal}
        justifyContent={floatToJustifyContent(props?.EEStringProps?.align)}
      >
        <EEString
          {...props?.EEStringProps}
          value={props?.value}
          onChange={(e: any) => props?.onChangeValue && props?.onChangeValue(props, e.target.value)}
          isDefaultValue
        />
      </CellRendererWrapper>
    )
  },
}
