import { type } from 'os'
import { IGostBill, ISubBill, ProjectType, TBudgetType } from '../../types/commos-def'
import { errorHandler, req, successHandler } from '../api'
import { ISubbillLinkToOperationBody, ISubBillPair, ISubLinkToPrPrOperationBody } from '../definitions'

export class AccountantAPI {
  static baseURL: string = '/accountant'

  static async createSubBill(body: { gostBillID: number; name: string }): Promise<ISubBill> {
    return await req
      .post(this.baseURL + '/sub-bills/create', body)
      .then(({ data }) => {
        successHandler(`Субсчет создан`)
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async updateSubBill(body: { subBillID: number; name: string }): Promise<void> {
    return await req
      .put(this.baseURL + `/sub-bills/${body.subBillID}/update`, { name: body.name })
      .then(({ data }) => {
        return successHandler(`Субсчет изменен`)
      })
      .catch((error) => errorHandler(error))
  }

  static async deleteSubBill(subBillID: number): Promise<void> {
    return await req
      .delete(this.baseURL + `/sub-bills/${subBillID}/delete`)
      .then(({ data }) => {
        return successHandler(`Субсчет удален`)
      })
      .catch((error) => errorHandler(error))
  }

  static async getGostBills(): Promise<{ data: (IGostBill & { countSubBills: number })[] }> {
    return await req
      .get('/bdr' + this.baseURL + '/gost-bills/get')
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async getSubBillsListByGostBill(gostBillID: number): Promise<{ data: ISubBill[] }> {
    return await req
      .get(this.baseURL + '/sub-bills/get-list/by-gost-bill', { gostBillID: gostBillID })
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async getSubBillsPairs(budgetType: ProjectType): Promise<{ data: ISubBillPair[] }> {
    return await req
      .get(this.baseURL + '/sub-bills/get-list/pairs', { budgetType })
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async subBillToOperation(
    budgetType: TBudgetType,
    type: 'link' | 'unlink',
    body: ISubbillLinkToOperationBody,
  ): Promise<void> {
    return await req
      .post(this.baseURL + `/sub-bills/${type}-${type == 'link' ? 'to' : 'from'}-${budgetType}-budget-mass`, body)
      .then(({ data }) => {
        return successHandler(`Субсчет ${type == 'link' ? 'привязан' : 'отвязан'}`)
      })
      .catch((error) => errorHandler(error))
  }

  static async subBillToPrPrOperation(type: 'link' | 'unlink', body: ISubLinkToPrPrOperationBody): Promise<void> {
    let methodSubUrl = '/sub-bills/update-production-program-links'
    return await req
      .post(this.baseURL + methodSubUrl, { data: [body] })
      .then(({ data }) => {
        return successHandler(`Субсчет ${type == 'link' ? 'привязан' : 'отвязан'}`)
      })
      .catch((error) => errorHandler(error))
  }
}
